
import React, { useEffect, useRef, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Pages/Header";
import "../Style/Header.css";
import "react-multi-carousel/lib/styles.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";




import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { Observer } from 'gsap/all';
import $ from 'jquery';
import useDeviceScreen from '../hooks/UseDeviceScreens';

import '../landing.scss';
import '../style-new.css';
import '../style-new2.scss';
const imageRootPath = "https://fs.cdnxn.com/lsm99king-next";

gsap.registerPlugin(useGSAP, Observer);

const w = (v) => (v / 1440 * 100).toFixed(2) + '%';
const h = (v) => (v / 1024 * 100).toFixed(2) + '%';

export default function Home() {

	const containerRef = useRef<HTMLDivElement>(null);
	const containerMobileRef = useRef<HTMLDivElement>(null);
	const containerDesktopRef = useRef<HTMLDivElement>(null);

	const [isEnding, setIsEnding] = useState(false);
	const [ready, setReady] = useState(false);
	const { isMobile, isDesktop } = useDeviceScreen('desktop');

	const [desktopToForm, setDesktopToForm] = useState(null);
	const [desktopShowForm, setDesktopShowForm] = useState(null);
	const [freezeTrigger, setFreezeTrigger] = useState(false);
	const [freezeObserver, setFreezeObserver] = useState(null);
	const [showForm, setShowForm] = useState(null);


	const openForm = (form) => {
		if (isDesktop) {
			if (desktopShowForm === 'register')
				window.location.replace('https://gamev2.lsm99king.bet/register');//toggleRegister().then(() => toggleLogin());

			else if (desktopShowForm === 'login') {
				window.location.replace('https://gamev2.lsm99king.bet/login'); //toggleLogin().then(() => toggleRegister());
			}

			return;
		}

		setShowForm(form);
	};

	const onRegister = () => {
		window.location.replace('https://gamev2.lsm99king.bet/register');
	}

	const onLogin = () => {
		window.location.replace('https://gamev2.lsm99king.bet/login');
	}





	// GSAP Desktop
	useGSAP(async (context, contextSafe) => {
		if (!isDesktop) return;

		let currentIndex = 0, animating = false, t1 = 3;

		const observer = Observer.create({
			type: "wheel,touch",
			wheelSpeed: -1,
			onDown: () => !animating && onObserve(currentIndex - 1, -1),
			onUp: () => !animating && onObserve(currentIndex + 1, 1),
			tolerance: 10,
			preventDefault: true,
		});

		observer.disable();

		const fadeKing = gsap.timeline({ paused: true })
			.to('.king-title-top', { bottom: '2.63%', opacity: 1, duration: 1 }, 0)
			.to('.king-title-bottom', { bottom: '-11.23%', opacity: 1, duration: 1 }, 0)

			.to('.king-title-top', { bottom: '17.77%', duration: 1 }, 1)
			.to('.king-title-bottom', { bottom: '3.9%', duration: 1 }, 1)
			.to('.king-welcome', { bottom: '38.37%', opacity: 1, duration: 1 }, 1)

			.to('.king-welcome', { bottom: '60.93%', opacity: 1, duration: 1 }, 2)
			.to('.the-king', { width: 402, bottom: '7.81%', opacity: 1, duration: 1 }, 2)
			.to('.btn-register', { left: '17.08%', opacity: 1, duration: 1 }, 2)
			.to('.btn-login', { right: '17.08%', opacity: 1, duration: 1 }, 2)

			.to('.scroll-mouse', { bottom: 46, opacity: 1, duration: 1 }, 3)
			.to('.scroll-text', {
				bottom: 24, opacity: 1, duration: 1, onComplete: () => {
					// if (!freezeObserver) observer.enable();
					observer.enable();
				}
			}, 3);

		if (!ready) {
			gsap.timeline({
				onComplete: () => {
					setReady(true);
				}
			})
				// .to(null, {
				// 	onComplete: () => {
				// 		// loadingRef.current?.close();
				// 		console.log('loading onComplete');
				// 	}
				// }, t1++)
				.to('.cloud2-1', { top: '53.32%', left: '70.69%', duration: .75 }, t1)
				.to('.cloud2-2', { top: '67.18%', left: '73.61%', duration: .75 }, t1)
				.to('.cloud3-1', { top: '-44.72%', left: '-43.125%', duration: .75 }, t1)
				.to('.cloud3-2', { top: '-33.2%', left: '0.83%', duration: .75 }, t1)
				.to('.cloud4-1', { top: '-46.19%', right: '-72.56%', duration: .75 }, t1)
				.to('.cloud4-2', { top: '-41.74%', right: '-59.44%', duration: .75 }, t1)
				.to('.cloud5-1', { top: '18.75%', right: '-24.02%', duration: .75 }, t1)
				.to('.cloud5-2', { top: '23.53%', right: '-24.51%', duration: .75 }, t1)
				.to('.cloud6-1', { top: '32.03%', left: '-54.86%', duration: .75 }, t1)
				.to('.cloud6-2', { top: '45.89%', left: '-59.09%', duration: .75 }, t1)
				.to('.frame-op', { opacity: 0.15, duration: .75 }, t1)
				.to('.welcome-text', { width: 541, bottom: -60, duration: 1 }, t1)
				.to('.logo-outer', { scale: 0.47, top: -66, duration: 1 }, t1++)

				.to('.frame-bg', { top: '-73.43%', opacity: 0, duration: 1.5 }, ++t1)
				.to('.bg-king', {
					width: 3617, scale: 1, opacity: 1, duration: 1.5, onStart: () => {
						gsap.set('.frame-2', { display: 'flex' })
					}
				}, t1)
				.to('.frame-castle', { top: '-73.43%', opacity: 0, duration: 1.25 }, t1)
				.to('.pole-start', { height: 3270, left: '-37.15%', opacity: 0, duration: 1.25 }, t1)
				.to('.pole-end', { height: 3270, right: '-37.15%', opacity: 0, duration: 1.25 }, t1)
				.to('.frame-op', { opacity: 0, duration: 1.25 }, t1)
				.to('.welcome-text', { bottom: -143, opacity: 0, duration: 1.25 }, t1)
				.to('.cloud2-1', { top: '54.39%', left: '100%', opacity: 0, duration: .75 }, t1)
				.to('.cloud2-2', { top: '68.26%', left: '102.88%', opacity: 0, duration: .75 }, t1)
				.to('.cloud3-1', { top: '-61.91%', left: '-63.5%', opacity: 0, duration: .75 }, t1)
				.to('.cloud3-2', { top: '-50.39%', left: '-15.17%', opacity: 0, duration: .75 }, t1)
				.to('.cloud4-1', { top: '-52.73%', right: '-80.69%', opacity: 0, duration: .75 }, t1)
				.to('.cloud4-2', { top: '-48.29%', right: '-67.88%', opacity: 0, duration: .75 }, t1)
				.to('.cloud5-1', { top: '19.53%', right: '-32.15%', opacity: 0, duration: .75 }, t1)
				.to('.cloud5-2', { top: '24.31%', right: '-32.15%', opacity: 0, duration: .75 }, t1)
				.to('.cloud6-1', { top: '32.22%', left: '-84.23%', opacity: 0, duration: .75 }, t1)
				.to('.cloud6-2', { top: '46.09%', left: '-102.95%', opacity: 0, duration: .75 }, t1)
		} else {
			// gsap.set('.logo-outer', { scale: 0.47, top: -66 });
			// gsap.set('.bg-king', { width: 3617, scale: 1, opacity: 1 })
			// gsap.set('.frame-2', { display: 'flex' })

			// fadeKing.play();
			gsap.set('.logo-outer', { scale: 0.47, top: -66 });
			gsap.set('.bg-king', { width: 3617, scale: 1, opacity: 1 })
			gsap.set('.frame-2', { display: 'flex' })

			console.log(desktopToForm);

			if (desktopToForm) {
				fadeKing.progress(100);

				if (desktopToForm.includes('register')) toggleRegister();
				else if (desktopToForm.includes('login')) toggleLogin();
			} else {
				fadeKing.play();
			}
		}

		const startingFade = gsap.timeline({ paused: true })
			.to('.logo-outer', { top: -254, duration: 1 }, 0)
			.fromTo('.frame-2', { scale: 1, duration: 1 }, { scale: 2.5, duration: 1 }, 0)
			.fromTo('.frame-3', { display: 'none' }, { display: 'flex' }, 0.75)
			.to('.frame-3 > .mask', { scale: 1, x: 0, y: 0, top: '35.15%', duration: 1 }, 0.75)

		const smartphoneFade = gsap.timeline({
			paused: true, onComplete: () => {
				smartphoneShow.play();
			}, onReverseComplete: () => {
				smartphoneShow.reverse();
			}
		})
			.to('.frame-3 > .landing-navbar', { top: '12.2%', right: '-29.68%', x: 0, opacity: 1, duration: 1 }, 0)
			.to('.frame-3 > .w-header', { top: '11.71%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 > .mask', { top: '11.71%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 > .w-title', { top: '24.21%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 > .w-subtitle', { top: '32.81%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 > .landing-navbar', { top: '5.46%', right: '50%', x: '50%', duration: 1 }, 1)
			.to('.frame-3 > .w-header', { top: '2.63%', opacity: 0, duration: 1 }, 1)
			.to('.frame-3 > .w-title', { top: '15.82%', duration: 1 }, 1)
			.to('.frame-3 > .w-subtitle', { top: '23.43%', duration: 1 }, 1)
			.to('.frame-3 > .smartphone', { top: '39.35%', rotate: 0, opacity: 1 }, 2)

		const smartphoneShow = gsap.timeline({ paused: true })
			.to('.frame-3 .phone-wrapper', { duration: 1, left: 0 }, 0)
			.to('.frame-3 .phone-2', { duration: 1, x: 0, y: 0, top: 42, left: 130, rotate: 8.21 }, 1)
			.to('.frame-3 .phone-3', { duration: 1, x: 0, y: 0, top: 100, left: 270, rotate: 6.2 }, 1)
			.to('.frame-3 .phone-4', { duration: 1, x: 0, y: 0, top: 52, left: 413.5, rotate: 11.45 }, 1)
			.to('.frame-3 .phone-5', { duration: 1, x: 0, y: 0, top: 28, left: 557.5, rotate: 18.25 }, 1)

		const balloonFade = gsap.timeline({ paused: true })
			.to('.frame-3 .balloon-wrapper', { top: '-240.23%', opacity: 0, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .balloon-header', { top: '15.42%', opacity: 1, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .bt-1', { top: '18.26%', opacity: 1, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .bt-2', { top: '18.26%', opacity: 1, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .bt-3', { top: '25.04%', opacity: 1, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .bt-4', { top: '25.04%', opacity: 1, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .bt-5', { top: '34.57%', opacity: 1, ease: 'power1.inOut', duration: 4 }, 0)
			.to('.frame-3 .w-title', { top: '91.4%', opacity: 0, duration: 4 }, 0)
			.to('.frame-3 .w-subtitle', { top: '99.02%', opacity: 0, duration: 4 }, 0)
			.to('.frame-3 > .smartphone', { top: '114.84%', opacity: 0, duration: 4 }, 0)

		const preShowingSport = gsap.timeline({ paused: true })
			.to('.frame-3 .balloon-header', { top: '-12.59%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .bt-1', { top: '-9.71%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .bt-2', { top: '-9.71%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .bt-3', { top: '-2.97%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .bt-4', { top: '-2.97%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .bt-5', { top: '6.54%', opacity: 0, duration: 1 }, 0)

			.to('.frame-3 > .category-show', { top: '15.03%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-1', { opacity: 0, duration: 1 }, { width: '21.61%', top: '27.19%', right: '20.96%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-2', { opacity: 0, duration: 1 }, { width: '8.02%', top: '44.62%', right: '35.93%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-3', { opacity: 0, duration: 1 }, { width: '32.7%', top: '13.08%', right: '0.38%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-4', { opacity: 0, duration: 1 }, { width: '23.43%', bottom: '6.25%', right: '27.84%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-5', { opacity: 0, duration: 1 }, { width: '28.95%', bottom: '10.93%', right: '2.08%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-6', { opacity: 0, duration: 1 }, { width: '49.79%', bottom: '11.23%', left: '6.38%', opacity: 1, duration: 1 }, 0)

		const preShowingCasino = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element1 = document.querySelector('.frame-3 .cate-1');
				const element2 = document.querySelector('.frame-3 .cate-2');

				element1?.classList.toggle('active', p <= 0.5);
				element2?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.frame-3 .sp-1', { width: '21.61%', top: '27.19%', right: '20.96%', duration: 1 }, { right: '7.04%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-2', { width: '8.02%', top: '44.62%', right: '35.93%', duration: 1 }, { width: '1.98%', top: '44.62%', right: '35.93%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-3', { width: '32.7%', top: '13.08%', right: '0.38%', duration: 1 }, { width: '32.7%', top: '13.08%', right: '-14.16%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-4', { width: '23.43%', bottom: '6.25%', right: '27.84%', duration: 1 }, { width: '23.43%', bottom: '6.25%', right: '7.84%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-5', { width: '28.95%', bottom: '10.93%', right: '2.08%', duration: 1 }, { width: '6.73%', bottom: '45.94%', right: '24.3%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sp-6', { width: '49.79%', bottom: '11.23%', left: '6.38%', duration: 1 }, { width: '41.66%', bottom: '-17.87%', left: '6.38%', opacity: 0, duration: 1 }, 0)

			.fromTo('.frame-3 .cs-1', { width: w(506), top: h(279), left: '-16.04%', opacity: 0, duration: 1 }, { width: w(506), top: h(279), left: '1.59%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-2', { width: w(23), top: 342, right: w(-23), opacity: 0, duration: 1 }, { width: w(81.5), top: h(329.5), right: w(2), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-3', { width: w(142), top: h(404), right: w(81), opacity: 0, duration: 1 }, { width: w(504), top: h(547.5), right: w(372), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-4', { width: w(25), top: 341, right: w(28), opacity: 0, duration: 1 }, { width: w(89.5), top: h(322), right: w(183.5), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-5', { width: w(38.5), top: h(367.5), right: w(-9), opacity: 0, duration: 1 }, { width: w(137), top: h(418), right: w(51), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-6', { width: w(64.5), bottom: h(518.5), right: w(39.5), opacity: 0, duration: 1 }, { width: w(230), bottom: h(118.5), right: w(224), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-7', { width: w(63), bottom: h(566), right: w(39.5), opacity: 0, duration: 1 }, { width: w(225), bottom: h(286), right: w(223.5), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-8', { width: w(600), top: h(533), left: w(420), opacity: 0, duration: 1 }, { width: w(720), top: h(323), left: w(351), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-9', { width: w(226), bottom: h(283), right: w(-133.5), opacity: 0, duration: 1 }, { width: w(409.5), bottom: h(216), right: w(67.5), opacity: 1, duration: 1 }, 0)

		const preShowingSlot = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element2 = document.querySelector('.frame-3 .cate-2');
				const element3 = document.querySelector('.frame-3 .cate-3');

				element2?.classList.toggle('active', p <= 0.5);
				element3?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.frame-3 .cs-1', { width: w(506), top: h(279), left: '1.59%', duration: 1 }, { width: w(506), top: h(279), left: '-16.04%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-2', { width: w(81.5), top: h(329.5), right: w(2), duration: 1 }, { width: w(23), top: 342, right: w(-23), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-3', { width: w(504), top: h(547.5), right: w(372), duration: 1 }, { width: w(142), top: h(404), right: w(81), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-4', { width: w(89.5), top: h(322), right: w(183.5), duration: 1 }, { width: w(25), top: 341, right: w(28), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-5', { width: w(137), top: h(418), right: w(51), duration: 1 }, { width: w(38.5), top: h(367.5), right: w(-9), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-6', { width: w(230), bottom: h(118.5), right: w(224), duration: 1 }, { width: w(64.5), bottom: h(518.5), right: w(39.5), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-7', { width: w(225), bottom: h(286), right: w(223.5), duration: 1 }, { width: w(63), bottom: h(566), right: w(39.5), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-8', { width: w(720), top: h(323), left: w(351), duration: 1 }, { width: w(600), top: h(533), left: w(420), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .cs-9', { width: w(409.5), bottom: h(216), right: w(67.5), duration: 1 }, { width: w(226), bottom: h(283), right: w(-133.5), opacity: 0, duration: 1 }, 0)

			.fromTo('.frame-3 .sl-1', { width: w(244), top: h(409), left: w(-77), opacity: 0, duration: 1 }, { width: w(534), top: h(291.5), left: w(1), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-2', { width: w(270), top: h(767.5), left: w(395), opacity: 0, duration: 1 }, { width: w(473.5), top: h(238.5), left: w(253.5), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-3', { width: w(251), top: h(495.5), left: w(603.5), opacity: 0, duration: 1 }, { width: w(251), top: h(309.5), left: w(603.5), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-4', { width: w(115), opacity: 0, duration: 1 }, { width: w(115), bottom: h(214), left: w(109), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-5', { width: w(89), opacity: 0, duration: 1 }, { width: w(89), bottom: h(91), right: w(528.5), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-6', { width: w(110.5), opacity: 0, duration: 1 }, { width: w(110.5), bottom: h(62.5), left: w(156.5), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-7', { width: w(600), bottom: h(182.5), right: w(-479), opacity: 0, duration: 1 }, { width: w(714), bottom: h(108), right: w(71), opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-8', { width: w(135.5), opacity: 0, duration: 1 }, { width: w(135.5), bottom: h(93), right: w(234), opacity: 1, duration: 1 }, 0)

		const preShowingLotto = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element3 = document.querySelector('.frame-3 .cate-3');
				const element4 = document.querySelector('.frame-3 .cate-4');

				element3?.classList.toggle('active', p <= 0.5);
				element4?.classList.toggle('active', p > 0.5);

				// $('.frame-3 .cate-3')?.classList.toggle('active', p <= 0.5);
				// $('.frame-3 .cate-4')?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.frame-3 .sl-1', { width: w(534), top: h(291.5), left: w(1), duration: 1 }, { width: w(244), top: h(409), left: w(-77), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-2', { width: w(473.5), top: h(238.5), left: w(253.5), duration: 1 }, { width: w(270), top: h(767.5), left: w(395), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-3', { width: w(251), top: h(309.5), left: w(603.5), duration: 1 }, { width: w(251), top: h(495.5), left: w(603.5), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-4', { width: w(115), bottom: h(214), left: w(109), duration: 1 }, { width: w(115), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-5', { width: w(89), bottom: h(91), right: w(528.5), duration: 1 }, { width: w(89), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-6', { width: w(110.5), bottom: h(62.5), left: w(156.5), duration: 1 }, { width: w(110.5), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-7', { width: w(714), bottom: h(108), right: w(71), duration: 1 }, { width: w(600), bottom: h(182.5), right: w(-479), opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .sl-8', { width: w(135.5), bottom: h(93), right: w(234), duration: 1 }, { width: w(135.5), opacity: 0, duration: 1 }, 0)

			.fromTo('.frame-3 .lt-1', { width: '32.43%', right: '-11.31%', opacity: 0, duration: 1 }, { width: '32.43%', bottom: '8.59%', right: '7.01%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-2', { width: '13.81%', opacity: 0, duration: 1 }, { width: '37.15%', top: '27.14%', left: '0.97%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-3', { width: '11.97%', opacity: 0, duration: 1 }, { width: '27.5%', top: '23.14%', right: '2.84%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-4', { width: '7.29%', opacity: 0, duration: 1 }, { width: '13.15%', top: '36.32%', right: '27.67%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-5', { width: '17.22%', right: '14.09%', opacity: 0, duration: 1 }, { width: '17.22%', bottom: '2.92%', right: '25.86%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-6', { width: '16.18%', right: '-3.05%', opacity: 0, duration: 1 }, { width: '16.18%', bottom: '0.04%', right: '15.2%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-7', { width: '41.66%', bottom: '-17.13%', opacity: 0, duration: 1 }, { width: '52.63%', bottom: '7.03%', left: '10.76%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-8', { width: '9.96%', left: '49.79%', opacity: 0, duration: 1 }, { width: '19.2%', top: '36.91%', left: '40.55%', opacity: 1, duration: 1 }, 0)

		const preShowingOther = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element4 = document.querySelector('.frame-3 .cate-4');
				const element5 = document.querySelector('.frame-3 .cate-5');

				element4?.classList.toggle('active', p <= 0.5);
				element5?.classList.toggle('active', p > 0.5);

				// $('.frame-3 .cate-4')?.classList.toggle('active', p <= 0.5);
				// $('.frame-3 .cate-5')?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.frame-3 .lt-1', { width: '32.43%', bottom: '8.59%', right: '7.01%', duration: 1 }, { width: '32.43%', right: '-11.31%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-2', { width: '37.15%', top: '27.14%', left: '0.97%', duration: 1 }, { width: '13.81%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-3', { width: '27.5%', top: '23.14%', right: '2.84%', duration: 1 }, { width: '11.97%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-4', { width: '13.15%', top: '36.32%', right: '27.67%', duration: 1 }, { width: '7.29%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-5', { width: '17.22%', bottom: '2.92%', right: '25.86%', duration: 1 }, { width: '17.22%', right: '14.09%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-6', { width: '16.18%', bottom: '0.04%', right: '15.2%', duration: 1 }, { width: '16.18%', right: '-3.05%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-7', { width: '52.63%', bottom: '7.03%', left: '10.76%', duration: 1 }, { width: '41.66%', bottom: '-17.13%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .lt-8', { width: '19.2%', top: '36.91%', left: '40.55%', duration: 1 }, { width: '9.96%', left: '49.79%', opacity: 0, duration: 1 }, 0)

			.fromTo('.frame-3 .ot-1', { width: '33.22%', bottom: '22.16%', right: '-5.79%', opacity: 0, duration: 1 }, { width: '33.22%', bottom: '22.07%', right: '-5.79%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-2', { width: '21.7%', top: '30%', right: '-11.38%', opacity: 0, duration: 1 }, { width: '21.7%', top: '30.17%', right: '11.66%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-3', { width: '21.7%', top: '37.25%', right: '-33.68%', opacity: 0, duration: 1 }, { width: '21.7%', top: '37.35%', right: '12.08%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-4', { width: '21.7%', top: '28.46%', right: '-23.75%', opacity: 0, duration: 1 }, { width: '21.7%', top: '28.56%', right: '0.76%', opacity: 1, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-5', { width: '28.26%', bottom: '-31.25%', right: '24.2%', opacity: 0, rotate: -127.21, duration: 1 }, { width: '34.16%', bottom: '2.39%', right: '5.41%', opacity: 1, rotate: -179.19, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-6', { width: '34.09%', bottom: '-34.17%', left: '-22.5%', rotate: -27.99, opacity: 0, duration: 1 }, { width: '39.54%', bottom: '6.54%', left: '-4.16%', opacity: 1, rotate: 21.07, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-7', { width: '41.66%', bottom: '6.59%', left: '27.15%', opacity: 0, duration: 1 }, { width: '52.08%', bottom: '17.67%', left: '21.94%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .pvt-4', { opacity: 0 })

		const providerShowing = gsap.timeline({ paused: true })
			.fromTo('.frame-3 .ot-1', { width: '33.22%', bottom: '22.07%', right: '-5.79%', duration: 1 }, { width: '33.22%', bottom: '22.16%', right: '-5.79%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-2', { width: '21.7%', top: '30.17%', right: '11.66%', duration: 1 }, { width: '21.7%', top: '30%', right: '-11.38%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-3', { width: '21.7%', top: '37.35%', right: '12.08%', duration: 1 }, { width: '21.7%', top: '37.25%', right: '-33.68%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-4', { width: '21.7%', top: '28.56%', right: '0.76%', duration: 1 }, { width: '21.7%', top: '28.46%', right: '-23.75%', opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-5', { width: '34.16%', bottom: '2.39%', right: '5.41%', rotate: -179.19, duration: 1 }, { width: '28.26%', bottom: '-31.25%', right: '24.2%', opacity: 0, rotate: -127.21, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-6', { width: '39.54%', bottom: '6.54%', left: '-4.16%', rotate: 21.07, duration: 1 }, { width: '34.09%', bottom: '-34.17%', left: '-22.5%', rotate: -27.99, opacity: 0, duration: 1 }, 0)
			.fromTo('.frame-3 .ot-7', { width: '52.08%', bottom: '17.67%', left: '21.94%', duration: 1 }, { width: '41.66%', bottom: '6.59%', left: '27.15%', opacity: 0, duration: 1 }, 0)

			.to('.frame-3 .category-show', { opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .provider-header', { top: '42.48%', left: '7.5%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .pvt-1', { top: '46.63%', left: '7.5%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .pvt-2', { top: '46.63%', left: '26.44%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .pvt-3', { top: '56.44%', left: '7.5%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .pvt-4', { top: '56.44%', left: '32.48%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .provider-list', { top: '18.55%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .provider-list .pv', {
				opacity: 1,
				duration: 1.5,
				stagger: {
					amount: 1.5,
					grid: 'auto',
					ease: 'none',
					from: 'random'
				},
				onUpdate() {
					const p = this.progress();
					if (p > 0.5) providerWorld.play();
					else providerWorld.reverse();
				},
			}, 0);

		const providerWorld = gsap.timeline({ paused: true })
			.to('.frame-3 .pvt-5', { top: '56.64%', opacity: 1, duration: 0.5 }, 0)
			.to('.frame-3 .pvt-4', { top: '45.31%', opacity: 0, duration: 0.5 }, 0)

		const friendZoomIn = gsap.timeline({ paused: true })
			.to('.frame-3 .provider-wrapper', { opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .friend-wrapper', { opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .friend-bg', { opacity: 1, duration: 1 }, 1)
			.to('.frame-3 .friend-header', { top: '20.01%', opacity: 1, duration: 1 }, 1)
			.to('.frame-3 .ft-1', { top: '26.07%', opacity: 1, duration: 1 }, 1)
			.to('.frame-3 .ft-2', { top: '37.59%', opacity: 1, duration: 1 }, 1)
			.to('.frame-3 .ft-3', { top: '54.1%', opacity: 1, duration: 1 }, 1)
			.to('.frame-3 .ft-4', { top: '59.96%', opacity: 1, duration: 1 }, 1)
			.to('.frame-3 .ft-5', { top: '67.08%', opacity: 1, duration: 1 }, 1)

		const friendShowing = gsap.timeline({ paused: true })
			.to('.frame-3 .friend-header', { top: '-42.96%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .ft-1', { top: '-37.01%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .ft-2', { top: '-25.48%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .ft-3', { top: '-8.98%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .ft-4', { top: '-3.125%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .ft-5', { top: '4%', opacity: 0, duration: 1 }, 0)
			.to('.frame-3 .ipad-mock', { width: 1004, height: 692, top: '19.92%', left: 'calc(50% - 1004px / 2)', duration: 1 }, 0)
			.to('.frame-3 .friend-wrap', { width: 1004, height: 692, top: '19.92%', left: 'calc(50% - 1004px / 2)', duration: 1 }, 0)
			.to('.frame-3 .friend-bg', { width: 805, top: 34, right: 100, duration: 1 }, 0)
			// .to('.frame-3 .friend-bg', { width: 954, top: 34, right: 20, duration: 1 }, 0)

		const friendEnding = gsap.timeline({ paused: true })
			.to('.frame-3 .ipad-mock', { top: '-21.38%', duration: 1 }, 0)
			.to('.frame-3 .friend-wrap', { top: '-21.38%', duration: 1 }, 0)
			.to('.frame-3 .ft-6', { opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .ft-7', { opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .ft-8', { opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .ft-9', { opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .ft-10', { opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .ft-11', { opacity: 1, duration: 1 }, 0)

		// Fade Loopback
		const loopback = gsap.timeline({ paused: true })
			.to('.frame-3 .friend-wrapper', { opacity: 0, duration: 1 }, 0)
			.to('.frame-3 > .mask', { top: '35.15%', opacity: 1, duration: 1 }, 0)
			.to('.frame-3 .landing-navbar', { top: -82, opacity: 1, duration: 1 }, 0)

		const resetObserver = () => {
			loopback.progress(0).pause().revert();
			friendEnding.progress(0).pause().revert();
			friendShowing.progress(0).pause().revert();
			friendZoomIn.progress(0).pause().revert();
			providerShowing.progress(0).pause().revert();

			providerWorld.progress(0).pause().revert();

			preShowingOther.progress(0).pause().revert();
			preShowingLotto.progress(0).pause().revert();
			preShowingSlot.progress(0).pause().revert();
			preShowingCasino.progress(0).pause().revert();
			preShowingSport.progress(0).pause().revert();
			balloonFade.progress(0).pause().revert();
			smartphoneFade.progress(0).pause().revert();
			startingFade.progress(0).pause().revert();

			gsap.set('.frame-3 .pvt-4', { opacity: 0 })
			gsap.set('.frame-3 .sp-1', { opacity: 0 })
			gsap.set('.frame-3 .sp-2', { opacity: 0 })
			gsap.set('.frame-3 .sp-3', { opacity: 0 })
			gsap.set('.frame-3 .sp-4', { opacity: 0 })
			gsap.set('.frame-3 .sp-5', { opacity: 0 })
			gsap.set('.frame-3 .sp-6', { opacity: 0 })
			gsap.set('.frame-3 .friend-wrapper', { opacity: 0 })
		};

		const onObserve = contextSafe ? contextSafe((toIndex, direction) => {
			const fs = () => (animating = false, currentIndex = toIndex);
			const backward = direction === -1;

			console.log(toIndex);

			switch (toIndex) {
				case 0: {
					animating = true;
					startingFade.reverse().then(fs);
				} break;

				case 1: { // Starting Fade
					animating = true;
					if (backward) {
						smartphoneFade.reverse().then(fs);
					} else startingFade.play().then(fs);
				} break;

				case 2: { // Smartphone Fade
					animating = true;
					if (backward) {
						balloonFade.reverse().then(fs);
					} else smartphoneFade.play().then(fs);
				} break;

				case 3: { // Balloon Fade
					animating = true;
					if (backward) {
						preShowingSport.reverse().then(fs);
					} else balloonFade.play().then(fs);
				} break;

				case 4: { // Fade Pre Showing (1: Sport)
					animating = true;
					if (backward) {
						preShowingCasino.reverse().then(fs);
					} else preShowingSport.play().then(fs);
				} break;

				case 5: { // Fade Pre Showing (2: Casino)
					animating = true;
					if (backward) {
						preShowingSlot.reverse().then(fs);
					} else preShowingCasino.play().then(fs);
				} break;

				case 6: { // Fade Pre Showing (3: Slot)
					animating = true;
					if (backward) {
						preShowingLotto.reverse().then(fs);
					} else preShowingSlot.play().then(fs);
				} break;

				case 7: { // Fade Pre Showing (4: Lotto)
					animating = true;
					if (backward) {
						preShowingOther.reverse().then(fs);
					} else preShowingLotto.play().then(fs);
				} break;

				case 8: { // Fade Pre Showing (5: Other)
					animating = true;
					if (backward) {
						providerShowing.reverse().then(fs);
					} else preShowingOther.play().then(fs);
				} break;

				case 9: { // Provider Showing
					animating = true;
					if (backward) {
						friendZoomIn.reverse().then(fs);
					} else providerShowing.play().then(fs);
				} break;

				case 10: { // Friend Zooming
					animating = true;
					if (backward) {
						friendShowing.reverse().then(fs);
					} else friendZoomIn.play().then(fs);
				} break;

				case 11: { // Friend Showing
					animating = true;
					if (backward) {
						friendEnding.reverse().then(fs);
					} else friendShowing.play().then(fs);
				} break;

				case 12: { // Friend Ending
					animating = true;
					if (backward) {
						loopback.reverse().then(fs);
					} else friendEnding.play().then(fs);
				} break;

				case 13: { // Loopback
					animating = true;
					if (backward) {
						// navbarFade3.reverse().then(fs);
					} else loopback.play().then(() => {
						setIsEnding(true);
						observer.disable();
						startingFade.reverse().then(() => {
							animating = false;
							currentIndex = 0;

							resetObserver();

							observer.enable();
						})
					});
				} break;
			}
		}) : undefined;

		// set all timeline to default.
		if (desktopToForm) {
			console.log('unmount');
			resetObserver();
		}

	}, { scope: containerDesktopRef, dependencies: [isDesktop, ready, desktopToForm], revertOnUpdate: true });

	const { contextSafe } = useGSAP({ scope: containerRef });

	const closeForm = () => {
		if (isDesktop) {
			if (desktopShowForm === 'register') toggleRegister();
			else if (desktopShowForm === 'login') toggleLogin();
		} else {
			setShowForm('');
		}
	};

	const toggleLogin = contextSafe(() => {
		if (freezeTrigger) return Promise.resolve();

		return (resolver => { //return new Promise<void>(resolver => {
			if (desktopShowForm === 'login') {
				setFreezeTrigger(true);

				const tl = gsap.timeline()
					.to('.login-text', { top: 364, opacity: 0, duration: 1 }, 0)
					.to('.landing-login', { right: 'calc(100% + 40px)', duration: 1 }, 0)
					.to('.logo-outer', { left: 'calc(50% - 171px)', duration: 1 }, 1)
					.to('.frame-2 .the-king', { width: 402, bottom: '7.81%', left: 'calc(50% - 402px / 2)', duration: 1 }, 1)
					.to('.frame-2 .king-welcome', { bottom: '60.93%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .king-title-top', { bottom: '17.77%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .king-title-bottom', { bottom: '3.9%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .scroll-mouse', { bottom: 46, opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .scroll-text', { bottom: 24, opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .btn-login', { right: '17.08%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .btn-register', { left: '17.08%', opacity: 1, duration: 1 }, 1)

				tl.then(() => {
					setDesktopShowForm(null);
					setFreezeObserver(false);
					setFreezeTrigger(false);
					tl.clear();
					resolver();
				});
			} else {
				setFreezeObserver(true);
				setFreezeTrigger(true);
				setDesktopShowForm('login');

				const tl = gsap.timeline()
					.to('.logo-outer', { left: 840, duration: 1 }, 0)
					.to('.frame-2 .the-king', { width: 752, bottom: -250, left: 661.5, duration: 1 }, 0)
					.to('.frame-2 .king-welcome', { bottom: 492, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .king-title-top', { bottom: 231, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .king-title-bottom', { bottom: 89, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .scroll-mouse', { bottom: -74, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .scroll-text', { bottom: -96, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .btn-login', { right: '-2.98%', opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .btn-register', { left: '3.75%', opacity: 0, duration: 1 }, 0)
					.to('.landing-login', { right: 908, duration: 1 }, 0)
					.to('.login-text', { top: 210, opacity: 1, duration: 1 }, 1)

				tl.then(() => {
					tl.clear();
					setFreezeTrigger(false);
					resolver();
				});
			}
		});
	});

	const toggleRegister = contextSafe(() => {
		if (freezeTrigger) return Promise.resolve();

		return (resolver => { //return new Promise<void>(resolver => {
			if (desktopShowForm === 'register') {
				setFreezeTrigger(true);

				const tl = gsap.timeline()
					.to('.register-text', { top: 364, opacity: 0, duration: 1 }, 0)
					.to('.landing-register', { left: 'calc(100% + 40px)', duration: 1 }, 0)
					.to('.logo-outer', { left: 'calc(50% - 171px)', duration: 1 }, 1)
					.to('.frame-2 .the-king', { width: 402, bottom: '7.81%', left: 'calc(50% - 402px / 2)', duration: 1 }, 1)
					.to('.frame-2 .king-welcome', { bottom: '60.93%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .king-title-top', { bottom: '17.77%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .king-title-bottom', { bottom: '3.9%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .scroll-mouse', { bottom: 46, opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .scroll-text', { bottom: 24, opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .btn-login', { right: '17.08%', opacity: 1, duration: 1 }, 1)
					.to('.frame-2 .btn-register', { left: '17.08%', opacity: 1, duration: 1 }, 1)

				tl.then(() => {
					setDesktopShowForm(null);
					setFreezeObserver(false);
					setFreezeTrigger(false);
					tl.clear();
					resolver();
				});
			} else {
				setFreezeObserver(true);
				setFreezeTrigger(true);
				setDesktopShowForm('register');

				const tl = gsap.timeline()
					.to('.logo-outer', { left: 240, duration: 1 }, 0)
					.to('.frame-2 .the-king', { width: 746.5, bottom: -248, left: 64, duration: 1 }, 0)
					.to('.frame-2 .king-welcome', { bottom: 492, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .king-title-top', { bottom: 231, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .king-title-bottom', { bottom: 89, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .scroll-mouse', { bottom: -74, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .scroll-text', { bottom: -96, opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .btn-login', { right: '-2.98%', opacity: 0, duration: 1 }, 0)
					.to('.frame-2 .btn-register', { left: '3.75%', opacity: 0, duration: 1 }, 0)
					.to('.landing-register', { left: '62.84%', duration: 1 }, 0)
					.to('.register-text', { top: 215, opacity: 1, duration: 1 }, 1);

				tl.then(() => {
					tl.clear();
					setFreezeTrigger(false);
					resolver();
				});
			}
		});
	});


	

	// GSAP Mobile
	useGSAP(async (context, contextSafe) => {
		if (!isMobile) return;

		let currentIndex = 0, animating = false, t1 = 3;
		const __sd = 0.75;
		const __dsm = 0.75;

		const observer = Observer.create({
			type: "wheel,touch",
			wheelSpeed: -1,
			onDown: () => !animating && onObserve(currentIndex - 1, -1),
			onUp: () => !animating && onObserve(currentIndex + 1, 1),
			tolerance: 10,
			preventDefault: true,
		});

		observer.disable();

		const fadeKing = gsap.timeline({ paused: true })
			.to('.fade2 .king-welcome-text', { bottom: 577, duration: __dsm, ease: 'power2.out' }, 0)
			.to('.fade2 .lsm99king-text', { bottom: 440.5, duration: __dsm, ease: 'power2.out' }, 0)
			.to('.fade2 .the-king', { scale: 1, opacity: 1, duration: __dsm }, 0)
			.to('.fade2 .btn-login', { right: 24, opacity: 1, duration: __dsm }, 0)
			.to('.fade2 .btn-register', { left: 24, opacity: 1, duration: __dsm }, 0)
			.to('.fade2 .scroll-mouse', { bottom: 32, opacity: 1, duration: __dsm }, 0)
			.to('.fade2 .scroll-text', {
				bottom: 8, opacity: 1, duration: __dsm, onComplete: () => {
					gsap.set('.fade1', { display: 'none' });
					observer.enable();
				}
			}, 0)

		if (!ready) {
			gsap.timeline({ onComplete: () => setReady(true) })
				.to(null, {
					onComplete: () => {
						// loadingRef.current?.close();
					}
				}, t1++)
				.to('.cloud1-1', { left: -422, duration: 1 }, ++t1)
				.to('.cloud1-2', { left: -130.5, duration: 1 }, t1)
				.to('.cloud2-1', { top: 17, left: 178.5, duration: 1 }, t1)
				.to('.cloud2-2', { top: 33.5, left: 99, duration: 1 }, t1)
				.to('.cloud3-1', { top: 110, left: -272, duration: 1 }, t1)
				.to('.cloud3-2', { top: 157.5, left: -265.2, duration: 1 }, t1)
				.to('.cloud4-1', { top: -195, left: -34.8, duration: 1 }, t1)
				.to('.cloud4-2', { top: -143.25, left: -57, duration: 1 }, t1)
				.to('.cloud5-1', { top: 257, left: 287, duration: 1 }, t1)
				.to('.cloud5-2', { top: 300, left: 274, duration: 1 }, t1)
				.to('.cloud5-3', { top: 325.75, left: 263, duration: 1 }, t1)
				.to('.cloud6-1', { top: 721, left: -58, duration: 1 }, t1)
				.to('.cloud6-2', { top: 793.5, left: -89, duration: 1 }, t1)
				.to('.longshot', { top: 17, width: 864, duration: 1 }, t1)
				.to('.archway', { top: -47, width: 1573, duration: 1 }, t1)
				.to('.front-cliff', { top: 41, width: 1028, duration: 1 }, t1)
				.to('.logo-outer', { top: 10, duration: 1 }, t1)
				.to('.welcome-text', { bottom: -16, opacity: 1, duration: 1 }, t1)

				.to('.bg2', { top: -40, width: 2226, opacity: 0, duration: __sd }, t1 += 2)
				.to('.bg', { top: -40, width: 2751, opacity: 0, duration: __sd }, t1)
				.to('.bg-king', { opacity: 1, duration: __sd }, t1)
				.to('.castle', { top: 716.5, width: 2925, opacity: 0, duration: __sd }, t1)
				.to('.pavement', { top: 716.5, width: 2925, opacity: 0, duration: __sd }, t1)
				.to('.archway', { top: -300, width: 2000, opacity: 0, duration: __sd }, t1)
				.to('.longshot', { top: 73, width: 1511.5, opacity: 0, duration: __sd }, t1)
				.to('.above-cliff', { top: -40, width: 2225, opacity: 0, duration: __sd }, t1)
				.to('.front-cliff', { top: 115, width: 1798.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud1-1', { left: -550, opacity: 0, duration: __sd }, t1)
				.to('.cloud1-2', { left: -257.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud2-1', { left: 469.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud2-2', { left: 390, opacity: 0, duration: __sd }, t1)
				.to('.cloud3-1', { top: 77, left: -471, opacity: 0, duration: __sd }, t1)
				.to('.cloud3-2', { top: 124.5, left: -464.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud4-1', { top: -228, left: -134.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud4-2', { top: -176.25, left: -157, opacity: 0, duration: __sd }, t1)
				.to('.cloud5-1', { top: 253, left: 369.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud5-2', { top: 296, left: 356, opacity: 0, duration: __sd }, t1)
				.to('.cloud5-3', { top: 322, left: 345, opacity: 0, duration: __sd }, t1)
				.to('.cloud6-1', { top: 456, left: -122, width: 753.5, height: 402.5, opacity: 0, duration: __sd }, t1)
				.to('.cloud6-2', { top: 544, left: -160, width: 765, height: 408.5, opacity: 0, duration: __sd }, t1)
				.to('.welcome-text', { bottom: -116, opacity: 0, duration: __sd }, t1)

				.to('.fade2', { display: 'flex' }, t1)

		} else {
			gsap.set('.logo-outer', { top: 10 })
			gsap.set('.bg-king', { opacity: 1 })
			gsap.set('.fade1', { display: 'none' })
			gsap.set('.fade2', { display: 'flex' })
			fadeKing.play();
		}

		const startingFade3 = gsap.timeline({ paused: true })
			.fromTo('.fade2', { scale: 1, duration: 1 }, { scale: 2, duration: 1 }, 0)
			.fromTo('.fade3', { display: 'none' }, { display: 'flex' }, 0.5)
			.to('.fade3 > .mask', { scale: 1, x: 0, y: 0, duration: 1 }, 0.5)

		const navbarFade3 = gsap.timeline({ paused: true })
			.to('.fade3 > .mask', { top: 140 })
			.to('.fade3 > .navbar', { top: 24 })
			.fromTo('.fade3 > .title-1', { top: 329, opacity: 0 }, { top: 257, opacity: 1 })
			.fromTo('.fade3 > .subtitle-1', { top: 545, opacity: 0 }, { top: 361, opacity: 1 })

		const smartphoneShow = gsap.timeline({ paused: true })
			.to('.fade3 .phone-wrap', { duration: 1, left: 0 })
			.to('.fade3 .phone-2', { duration: 1, top: 25, left: 70, rotate: 5 }, 1)
			.to('.fade3 .phone-3', { duration: 1, top: 46, left: 139, rotate: 1 }, 1)
			.to('.fade3 .phone-4', { duration: 1, top: 34, left: 205, rotate: 9 }, 1)
			.to('.fade3 .phone-5', { duration: 1, top: 27, left: 274, rotate: 18 }, 1)

		const smartphoneFade3 = gsap.timeline({
			paused: true, onComplete: () => {
				smartphoneShow.play();
			}, onReverseComplete: () => {
				smartphoneShow.reverse();
			}
		})
			.to('.fade3 > .mask', { top: 10, opacity: 0 })
			.fromTo('.fade3 > .title-1', { top: 257 }, { top: 129 })
			.fromTo('.fade3 > .subtitle-1', { top: 361 }, { top: 230 })
			.to('.fade3 > .smartphone', { top: 478, rotate: 0, opacity: 1 })

		const balloonFade3 = gsap.timeline({ paused: true })
			.to('.fade3 > .title-1', { y: '100vh', duration: 1 }, 0)
			.to('.fade3 > .subtitle-1', { y: '100vh', duration: 1 }, 0)
			.to('.fade3 > .smartphone', { y: '100vh', duration: 1 }, 0)
			.to('.fade3 .balloon-1', { top: 129, duration: 1 }, 0)
			.to('.fade3 .balloon-2', { top: 784, duration: 1 }, 0)
			.to('.fade3 .balloon-3', { top: -345, duration: 1 }, 0)
			.to('.fade3 .balloon-4', { top: 39, duration: 1 }, 0)
			.to('.fade3 .balloon-5', { top: -345, duration: 1 }, 0)
			.to('.fade3 .balloon-6', { top: 331, duration: 1 }, 0)
			.to('.fade3 .balloon-7', { top: 225, duration: 1 }, 0)
			.to('.fade3 .balloon-8', { top: -171, duration: 1 }, 0)
			.to('.fade3 .balloon-9', { top: 459, duration: 1 }, 0)
			.to('.fade3 .balloon-10', { top: 61, duration: 1 }, 0)
			.to('.fade3 .balloon-11', { top: -121, duration: 1 }, 0)
			.to('.fade3 .balloon-texts', { top: 'calc(100% - 134px)', duration: 1 }, 0)
			.to('.fade3 .balloon-texts .t-1', { top: 0, duration: 1 }, 0)
			.to('.fade3 .balloon-texts .t-2', { top: 0, duration: 1 }, 0)

		// Fade Pre Category
		const preCategoryFade3 = gsap.timeline({ paused: true })
			.to('.fade3 .balloon-1', { top: -183, duration: 1 }, 0)
			.to('.fade3 .balloon-2', { top: 472, duration: 1 }, 0)
			.to('.fade3 .balloon-3', { top: -657, duration: 1 }, 0)
			.to('.fade3 .balloon-4', { top: -273, duration: 1 }, 0)
			.to('.fade3 .balloon-5', { top: -656, duration: 1 }, 0)
			.to('.fade3 .balloon-6', { top: 19, duration: 1 }, 0)
			.to('.fade3 .balloon-7', { top: -87, duration: 1 }, 0)
			.to('.fade3 .balloon-8', { top: -483, duration: 1 }, 0)
			.to('.fade3 .balloon-9', { top: 147, duration: 1 }, 0)
			.to('.fade3 .balloon-10', { top: -251, duration: 1 }, 0)
			.to('.fade3 .balloon-11', { top: -433, duration: 1 }, 0)
			.to('.fade3 .balloon-texts', { top: 'calc(100% - 446px)', duration: 1 }, 0)
			.to('.fade3 .balloon-text-2', { top: 0, duration: 1 }, 0)
			.to('.fade3 .cate-1', { top: 0, duration: 1 }, 0)
			.to('.fade3 .cate-2', { top: 0, duration: 1 }, 0)
			.to('.fade3 .cate-3', { top: 0, duration: 1 }, 0)
			.to('.fade3 .cate-4', { top: 0, duration: 1 }, 0)
			.to('.fade3 .cate-5', { top: 0, duration: 1 }, 0)

		// Fade Pre Showing (1: Sport)
		const preShowingSport = gsap.timeline({ paused: true })
			.to('.fade3 .balloon-1', { top: -623, duration: 1 }, 0)
			.to('.fade3 .balloon-2', { top: 31, duration: 1 }, 0)
			.to('.fade3 .balloon-3', { top: -1098, duration: 1 }, 0)
			.to('.fade3 .balloon-4', { top: -714, duration: 1 }, 0)
			.to('.fade3 .balloon-5', { top: -1098, duration: 1 }, 0)
			.to('.fade3 .balloon-6', { top: -422, duration: 1 }, 0)
			.to('.fade3 .balloon-7', { top: -528, duration: 1 }, 0)
			.to('.fade3 .balloon-8', { top: -924, duration: 1 }, 0)
			.to('.fade3 .balloon-9', { top: -294, duration: 1 }, 0)
			.to('.fade3 .balloon-10', { top: -625, duration: 1 }, 0)
			.to('.fade3 .balloon-11', { top: -873, duration: 1 }, 0)
			.to('.fade3 .balloon-texts', { top: -54, duration: 1 }, 0)

		// Fade Pre Showing (2: Casino)
		const preShowingCasino = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element1 = document.querySelector('.fade3 .cate-1');
				const element2 = document.querySelector('.fade3 .cate-2');


				element1?.classList.toggle('active', p <= 0.5);
				element2?.classList.toggle('active', p > 0.5);


				// $('.fade3 .cate-1')?.classList.toggle('active', p <= 0.5);
				// $('.fade3 .cate-2')?.classList.toggle('active', p > 0.5);
			}
		})

			.to('.fade3 .sp-1', { width: 77, right: 303, bottom: 215, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-2', { width: 64, top: -11, left: -13, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-3', { left: -163, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-4', { left: -88, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-5', { right: -214, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-6', { width: 110, top: -36, right: -50, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-7', { width: 31, top: 10, left: 21, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-8', { top: 10, left: -151, opacity: 0, duration: 1 }, 0)
			.to('.fade3 .sp-9', { bottom: -9, left: -143, opacity: 0, duration: 1 }, 0)

			.fromTo('.fade3 .cs-1', { width: 5, top: 11.5, right: 141, opacity: 0, duration: 1 }, { width: 44, top: 55.5, right: 29.5, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-2', { width: 31, top: 22, right: 154, opacity: 0, duration: 1 }, { width: 271.5, top: 147.5, right: 140, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-3', { width: 5.5, top: 16, right: 138.5, opacity: 0, duration: 1 }, { width: 48, top: 98.5, right: 8, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-4', { width: 8.5, top: 18.5, right: 144.5, opacity: 0, duration: 1 }, { width: 74, top: 117, right: 60, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-5', { width: 14, top: 27, right: 148, opacity: 0, duration: 1 }, { width: 124, top: 186.5, right: 92, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-6', { width: 14, top: 24, right: 137, opacity: 0, duration: 1 }, { width: 121, top: 167, right: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-7', { width: 248, top: 28, left: -184, opacity: 0, duration: 1 }, { width: 304.5, top: -11.5, left: 66.5, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-8', { width: 222, top: 0, left: -97, opacity: 0, duration: 1 }, { left: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-9', { width: 331, bottom: -120, right: 0, opacity: 0, duration: 1 }, { bottom: 6, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-10', { width: 77.5, bottom: -52, left: 50, opacity: 0, duration: 1 }, { bottom: 71.5, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-11', { width: 60, top: -34.5, right: 0, opacity: 0, rotate: -43.5, duration: 1 }, { top: 19, right: 1.5, opacity: 1, rotate: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-12', { width: 41.5, bottom: -25.5, left: 14.5, opacity: 0, duration: 1 }, { width: 55, bottom: 17, left: 8, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .cs-13', { width: 53, top: -33.5, right: 153.5, opacity: 0, duration: 1 }, { top: 9, right: 149, opacity: 1, duration: 1 }, 0)

		// Fade Pre Showing (3: Slot)
		const preShowingSlot = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element2 = document.querySelector('.fade3 .cate-2');
				const element3 = document.querySelector('.fade3 .cate-3');

				element2?.classList.toggle('active', p <= 0.5);
				element3?.classList.toggle('active', p > 0.5);


				// $('.fade3 .cate-2')?.classList.toggle('active', p <= 0.5);
				// $('.fade3 .cate-3')?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.fade3 .cs-1', { width: 44, top: 55.5, right: 29.5, duration: 1 }, { width: 5, top: 11.5, right: 141, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-2', { width: 271.5, top: 147.5, right: 140, duration: 1 }, { width: 31, top: 22, right: 154, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-3', { width: 48, top: 98.5, right: 8, duration: 1 }, { width: 5.5, top: 16, right: 138.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-4', { width: 74, top: 117, right: 60, duration: 1 }, { width: 8.5, top: 18.5, right: 144.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-5', { width: 124, top: 186.5, right: 92, duration: 1 }, { width: 14, top: 27, right: 148, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-6', { width: 121, top: 167, right: 0, duration: 1 }, { width: 14, top: 24, right: 137, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-7', { width: 304.5, top: -11.5, left: 66.5, duration: 1 }, { width: 248, top: 28, left: -184, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-8', { left: 0, duration: 1 }, { width: 222, top: 0, left: -97, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-9', { bottom: 6, duration: 1 }, { width: 331, bottom: -120, right: 0, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-10', { bottom: 71.5, duration: 1 }, { width: 77.5, bottom: -52, left: 50, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-11', { top: 19, right: 1.5, rotate: 0, duration: 1 }, { width: 60, top: -34.5, right: 0, opacity: 0, rotate: -43.5, duration: 1 }, 0)
			.fromTo('.fade3 .cs-12', { width: 55, bottom: 17, left: 8, duration: 1 }, { width: 41.5, bottom: -25.5, left: 14.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .cs-13', { top: 9, right: 149, duration: 1 }, { width: 53, top: -33.5, right: 153.5, opacity: 0, duration: 1 }, 0)

			.fromTo('.fade3 .sl-1', { width: 73, top: -10, right: -93, opacity: 0, duration: 1 }, { top: 2, right: 18, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-2', { width: 112, top: 203, right: -9, opacity: 0, duration: 1 }, { top: 48, right: 9, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-3', { width: 82, bottom: 127, right: 99, opacity: 0, duration: 1 }, { bottom: 236, right: 174, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-4', { width: 76.5, bottom: 63, right: 137, opacity: 0, duration: 1 }, { opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-6', { width: 286, top: -103.5, left: 24.5, opacity: 0, duration: 1 }, { width: 297, top: 11, left: 14, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-7', { width: 200.5, bottom: 50, left: -115, opacity: 0, duration: 1 }, { width: 169, left: 23, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-8', { width: 90, bottom: -34, left: -67, opacity: 0, duration: 1 }, { bottom: -5, left: 2, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-9', { width: 117, top: 244, right: -92.5, opacity: 0, duration: 1 }, { top: 80, right: 47.5, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-10', { width: 220, bottom: 0, right: -197, opacity: 0, duration: 1 }, { right: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-11', { width: 107, bottom: 184, right: -56.5, opacity: 0, duration: 1 }, { width: 86, right: 123, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-12', { width: 196.5, bottom: -149, left: 38, opacity: 0, duration: 1 }, { width: 243, bottom: 1, left: 40, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-13', { width: 73, bottom: 35, right: 164, opacity: 0, duration: 1 }, { bottom: 144, right: 180.5, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .sl-14', { width: 70, bottom: -40, right: 142, opacity: 0, duration: 1 }, { bottom: 44.5, right: 80, opacity: 1, duration: 1 }, 0)

		// Fade Pre Showing (4: Lotto)
		const preShowingLotto = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element3 = document.querySelector('.fade3 .cate-3');
				const element4 = document.querySelector('.fade3 .cate-4');

				element3?.classList.toggle('active', p <= 0.5);
				element4?.classList.toggle('active', p > 0.5);

				// $('.fade3 .cate-3')?.classList.toggle('active', p <= 0.5);
				// $('.fade3 .cate-4')?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.fade3 .sl-1', { top: 2, right: 18, duration: 1 }, { width: 73, top: -10, right: -93, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-2', { top: 48, right: 9, duration: 1 }, { width: 112, top: 203, right: -9, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-3', { bottom: 236, right: 174, duration: 1 }, { width: 82, bottom: 127, right: 99, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-4', { duration: 1 }, { width: 76.5, bottom: 63, right: 137, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-6', { width: 297, top: 11, left: 14, duration: 1 }, { width: 286, top: -103.5, left: 24.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-7', { width: 169, left: 23, duration: 1 }, { width: 200.5, bottom: 50, left: -115, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-8', { bottom: -5, left: 2, duration: 1 }, { width: 90, bottom: -34, left: -67, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-9', { top: 80, right: 47.5, duration: 1 }, { width: 117, top: 244, right: -92.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-10', { right: 0, duration: 1 }, { width: 220, bottom: 0, right: -197, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-11', { width: 86, right: 123, duration: 1 }, { width: 107, bottom: 184, right: -56.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-12', { width: 243, bottom: 1, left: 40, duration: 1 }, { width: 196.5, bottom: -149, left: 38, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-13', { bottom: 144, right: 180.5, duration: 1 }, { width: 73, bottom: 35, right: 164, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .sl-14', { bottom: 44.5, right: 80, duration: 1 }, { width: 70, bottom: -40, right: 142, opacity: 0, duration: 1 }, 0)

			.fromTo('.fade3 .lt-1', { width: 175, top: -93.5, left: -13, opacity: 0, duration: 1 }, { width: 220, top: 0, left: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-2', { width: 339.5, top: 156, left: -214, opacity: 0, duration: 1 }, { left: 7, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-3', { width: 156, bottom: -15.5, left: -126, opacity: 0, duration: 1 }, { width: 202, bottom: 0, left: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-4', { width: 105, top: -86, right: -115, opacity: 0, duration: 1 }, { width: 223, top: 0, right: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-6', { width: 207, bottom: 11, right: 116, opacity: 0, duration: 1 }, { right: 35, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-7', { width: 107.5, bottom: -10, right: -144.5, opacity: 0, duration: 1 }, { bottom: 0, right: 11, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-8', { width: 99.5, bottom: -34, right: -145, opacity: 0, duration: 1 }, { bottom: 150, right: 7, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-9', { width: 79, bottom: -107, right: 79, opacity: 0, duration: 1 }, { bottom: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-10', { width: 23.5, bottom: 0, left: 65.5, opacity: 0, duration: 1 }, { opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-11', { width: 23.5, bottom: 0, left: 152.5, opacity: 0, duration: 1 }, { opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .lt-12', { width: 75.5, top: 34.5, right: 144, opacity: 0, duration: 1 }, { right: 199, opacity: 1, duration: 1 }, 0)

		// Fade Pre Showing (5: Other)
		const preShowingOther = gsap.timeline({
			paused: true, onUpdate() {
				const p = this.progress();

				const element4 = document.querySelector('.fade3 .cate-4');
				const element5 = document.querySelector('.fade3 .cate-5');
				element4?.classList.toggle('active', p <= 0.5);
				element5?.classList.toggle('active', p > 0.5);

				// $('.fade3 .cate-4')?.classList.toggle('active', p <= 0.5);
				// $('.fade3 .cate-5')?.classList.toggle('active', p > 0.5);
			}
		})

			.fromTo('.fade3 .lt-1', { width: 220, top: 0, left: 0, duration: 1 }, { width: 175, top: -93.5, left: -13, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-2', { left: 7, duration: 1 }, { width: 339.5, top: 156, left: -214, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-3', { width: 202, bottom: 0, left: 0, duration: 1 }, { width: 156, bottom: -15.5, left: -126, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-4', { width: 223, top: 0, right: 0, duration: 1 }, { width: 105, top: -86, right: -115, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-6', { right: 35, duration: 1 }, { width: 207, bottom: 11, right: 116, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-7', { bottom: 0, right: 11, duration: 1 }, { width: 107.5, bottom: -10, right: -144.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-8', { bottom: 150, right: 7, duration: 1 }, { width: 99.5, bottom: -34, right: -145, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-9', { bottom: 0, duration: 1 }, { width: 79, bottom: -107, right: 79, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-10', { duration: 1 }, { width: 23.5, bottom: 0, left: 65.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-11', { duration: 1 }, { width: 23.5, bottom: 0, left: 152.5, opacity: 0, duration: 1 }, 0)
			.fromTo('.fade3 .lt-12', { right: 199, duration: 1 }, { width: 75.5, top: 34.5, right: 144, opacity: 0, duration: 1 }, 0)

			.fromTo('.fade3 .ot-1', { width: 339.5, top: -119, right: 14.5, opacity: 0, duration: 1 }, { top: 13, right: 12.5, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-2', { width: 282, bottom: -248, right: 22, opacity: 0, duration: 1 }, { bottom: 23.5, right: 22, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-3', { width: 208, bottom: -125, right: -61, opacity: 0, duration: 1 }, { bottom: 106, right: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-4', { width: 93.5, top: 123.5, left: 0, opacity: 0, duration: 1 }, { top: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-5', { width: 70.5, top: 267.5, left: 3, opacity: 0, duration: 1 }, { top: 68, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-6', { width: 133.5, top: 241.5, left: 0, opacity: 0, duration: 1 }, { top: 104, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-7', { width: 136, top: 404, left: 72, opacity: 0, duration: 1 }, { top: 158, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-8', { width: 293, bottom: 10, left: -161.5, opacity: 0, duration: 1 }, { left: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-9', { width: 75.5, bottom: 215, right: -233, opacity: 0, duration: 1 }, { width: 217, bottom: 73.5, right: 0, opacity: 1, duration: 1 }, 0)
			.fromTo('.fade3 .ot-10', { width: 125.5, bottom: 141.5, right: -233, opacity: 0, duration: 1 }, { width: 248, bottom: 0, right: 0, opacity: 1, duration: 1 }, 0)

		// Fade End Pre Showing
		const endShowing = gsap.timeline({ paused: true })
			.to('.fade3 .balloon-texts', { top: -555, duration: 1 }, 0)
			.to('.fade3 .pre-show', { opacity: 0.3, duration: 1 }, 0)
			.to('.fade3 .provider-section', { top: 313, duration: 1 }, 0)
			.to('.fade3 .provider-section .t-1', { top: 0, duration: 1 }, 0)
			.to('.fade3 .provider-section .t-3', { top: 0, duration: 1 }, 0)

		// Fade Providers
		const providerShowing = gsap.timeline({ paused: true })
			.to('.fade3 .balloon-texts', { top: '-100%', duration: 1 }, 0)
			.to('.fade3 .pre-show', { opacity: 0, duration: 1 }, 0)
			.to('.fade3 .provider-section', { top: 75, duration: 1 }, 0)
			.to('.fade3 .provider-section .pv', {
				opacity: 1,
				duration: 1,
				ease: "power1.inOut",
				stagger: {
					amount: 1.5,
					grid: 'auto',
					ease: 'none',
					from: 'random'
				}
			});

		// Fade Friend (Zoom In)
		const friendZoomIn = gsap.timeline({ paused: true })
			.to('.fade3 .provider-section', { top: '-100%', duration: 1 }, 0)
			.to('.fade3 .friend-section', { top: 0, duration: 1 }, 0)

		// Fade Friend (Showing)
		const friendShowing = gsap.timeline({ paused: true })
			.to('.fade3 .friend-bg', { opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-1', { top: 154, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-title-1', { top: 183, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-title-2', { top: 236, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-2', { top: 307, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-3', { top: 332, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-4', { top: 357, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-bg', { width: 350, top: 22, right: 'calc(50% - 366.5px / 2)', duration: 1 }, 1)
			// .to('.fade3 .friend-bg', { width: 364, top: 22, right: 'calc(50% - 366.5px / 2)', duration: 1 }, 1)
			.to('.fade3 .ipad-mock', { width: 391, top: 0, left: 'calc(50% - 383px / 2)', duration: 1 }, 1)
			.to('.fade3 .friend-text-1', { top: -204, opacity: 0, duration: 1 }, 1)
			.to('.fade3 .friend-title-1', { top: -133, opacity: 0, duration: 1 }, 1)
			.to('.fade3 .friend-title-2', { top: -80, opacity: 0, duration: 1 }, 1)
			.to('.fade3 .friend-text-2', { top: -69, opacity: 0, duration: 1 }, 1)
			.to('.fade3 .friend-text-3', { top: -44, opacity: 0, duration: 1 }, 1)
			.to('.fade3 .friend-text-4', { top: -25, opacity: 0, duration: 1 }, 1)

		// Fade Friend (Ending)
		const friendEnding = gsap.timeline({ paused: true })
			.to('.fade3 .friend-title-3', { top: 443, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-title-4', { top: 496, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-title-5', { bottom: 100, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-5', { top: 572, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-6', { top: 601, opacity: 1, duration: 1 }, 0)
			.to('.fade3 .friend-text-7', { top: 631, opacity: 1, duration: 1 }, 0)

		// Fade Loopback
		const loopback = gsap.timeline({ paused: true })
			.to('.fade3 .friend-section', { opacity: 0, duration: 1 }, 0)
			.to('.fade3 > .mask', { top: 296, opacity: 1, duration: 1 }, 0)
			.to('.fade3 > .mask', { scale: 19, x: -698, y: 96, duration: 1.25 }, 1)
			.to('.fade3', { opacity: 0, duration: 1 }, 1.5)
			.to('.fade2', { scale: 1, duration: 1 }, 1.5)
			.to('.fade3', { display: 'none' }, 2.5)

		const onObserve = (toIndex, direction) => {
			const fs = () => (animating = false, currentIndex = toIndex);
			const backward = direction === -1;

			switch (toIndex) {
				case 0: {
					animating = true;
					startingFade3.reverse().then(fs);
				} break;

				case 1: { // Starting Fade 3
					animating = true;
					if (backward) {
						navbarFade3.reverse().then(fs);
					} else startingFade3.play().then(fs);
				} break;

				case 2: { // Navbar Fade 3
					animating = true;
					if (backward) {
						smartphoneFade3.reverse().then(fs);
					} else navbarFade3.play().then(fs);
				} break;

				case 3: { // Smartphone Fade 3
					animating = true;
					if (backward) {
						balloonFade3.reverse().then(fs);
					} else smartphoneFade3.play().then(fs);
				} break;

				case 4: { // Balloons Fade 3
					animating = true;
					if (backward) {
						preCategoryFade3.reverse().then(fs);
					} else balloonFade3.play().then(fs);
				} break;

				case 5: { // Fade Pre Category
					animating = true;
					if (backward) {
						preShowingSport.reverse().then(fs);
					} else preCategoryFade3.play().then(fs);
				} break;

				case 6: { // Fade Pre Showing (1: Sport)
					animating = true;
					if (backward) {
						preShowingCasino.reverse().then(fs);
					} else preShowingSport.play().then(fs);
				} break;

				case 7: { // Fade Pre Showing (2: Casino)
					animating = true;
					if (backward) {
						preShowingSlot.reverse().then(fs);
					} else preShowingCasino.play().then(fs);
				} break;

				case 8: { // Fade Pre Showing (3: Slot)
					animating = true;
					if (backward) {
						preShowingLotto.reverse().then(fs);
					} else preShowingSlot.play().then(fs);
				} break;

				case 9: { // Fade Pre Showing (4: Lotto)
					animating = true;
					if (backward) {
						preShowingOther.reverse().then(fs);
					} else preShowingLotto.play().then(fs);
				} break;

				case 10: { // Fade Pre Showing (5: Other)
					animating = true;
					if (backward) {
						endShowing.reverse().then(fs);
					} else preShowingOther.play().then(fs);
				} break;

				case 11: { // Fade End Pre Showing
					animating = true;
					if (backward) {
						providerShowing.reverse().then(fs);
					} else endShowing.play().then(fs);
				} break;

				case 12: { // Fade Providers
					animating = true;
					if (backward) {
						friendZoomIn.reverse().then(fs);
					} else providerShowing.play().then(fs);
				} break;

				case 13: { // Fade Friend (Zoom In)
					animating = true;
					if (backward) {
						friendShowing.reverse().then(fs);
					} else friendZoomIn.play().then(fs);
				} break;

				case 14: { // Fade Friend (Showing)
					animating = true;
					if (backward) {
						friendEnding.reverse().then(fs);
					} else friendShowing.play().then(fs);
				} break;

				case 15: { // Fade Friend (Ending)
					animating = true;
					if (backward) {
						loopback.reverse().then(fs);
					} else friendEnding.play().then(fs);
				} break;

				case 16: { // Fade Loopback
					animating = true;
					setIsEnding(true);
					loopback.play().then(() => {
						loopback.progress(0).pause().revert();
						friendEnding.progress(0).pause().revert();
						friendShowing.progress(0).pause().revert();
						friendZoomIn.progress(0).pause().revert();
						providerShowing.progress(0).pause().revert();
						endShowing.progress(0).pause().revert();
						preShowingOther.progress(0).pause().revert();
						preShowingLotto.progress(0).pause().revert();
						preShowingSlot.progress(0).pause().revert();
						preShowingCasino.progress(0).pause().revert();
						preShowingSport.progress(0).pause().revert();
						preCategoryFade3.progress(0).pause().revert();
						balloonFade3.progress(0).pause().revert();
						smartphoneFade3.progress(0).pause().revert();
						navbarFade3.progress(0).pause().revert();
						startingFade3.progress(0).pause().revert();

						animating = false;
						currentIndex = 0;
					});
				} break;
			}
		}

		return () => {
			startingFade3.clear();
			navbarFade3.clear();
			smartphoneFade3.clear();
			balloonFade3.clear();
			preCategoryFade3.clear();
			preShowingSport.clear();
			preShowingCasino.clear();
			preShowingSlot.clear();
			preShowingLotto.clear();
			preShowingOther.clear();
			endShowing.clear();
			providerShowing.clear();
			friendZoomIn.clear();
			friendShowing.clear();
			friendEnding.clear();
			loopback.clear();
		};
	}, { scope: containerRef, dependencies: [isMobile, ready] });


  return (
    <>
		<div className='landing-container'>

			<div className='landing-mobile'>
				{isMobile ? (
					<React.Fragment>
						<section className='fade-logo'>
							<div className='logo-outer'>
								<div className='logo-inner'>
									<img className='king-logo' width='86' height='86' src={imageRootPath + '/images/landing/king-logo.webp'} alt='king logo' />
								</div>
							</div>
						</section>

						<section className='fade1 clearfix'>
							<img className='bg' width='1572' src={imageRootPath + '/images/landing/bg.webp'} alt='bg' />
							<img className='bg2' width='1272' src={imageRootPath + '/images/landing/bg2.webp'} alt='bg2' />

							<img className='longshot' width='1272' src={imageRootPath + '/images/landing/longshot.webp'} alt='longshot' />
							<img className='front-cliff' width='1272' src={imageRootPath + '/images/landing/front-cliff.webp'} alt='front cliff' />
							<img className='above-cliff' width='1272' src={imageRootPath + '/images/landing/above-cliff.webp'} alt='above cliff' />

							<img className='castle' width='1559' src={imageRootPath + '/images/landing/castle.webp'} alt='castle' />
							<img className='pavement' width='1572' src={imageRootPath + '/images/landing/pavement.webp'} alt='pavement' />
							<img className='archway' width='2350' src={imageRootPath + '/images/landing/archway.webp'} alt='archway' />

							<section className='fade-welcome'>
								<img className='welcome-text' width='313' src={imageRootPath + '/images/landing/welcome-text.webp'} alt='welcome text' />
							</section>

							<section className='fade-clouds'>
								<section className='fade-cloud5'>
									<img className='cloud5-1' width='351' height='188' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
									<img className='cloud5-2' width='220' height='117' src={imageRootPath + '/images/landing/cloud-r.webp'} alt='cloud' />
									<img className='cloud5-3' width='223' height='119' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />
								</section>

								<section className='fade-cloud1'>
									<img className='cloud1-1' width='567' height='303' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />
									<img className='cloud1-2' width='276' height='146' src={imageRootPath + '/images/landing/cloud.webp'} alt='cloud' />
								</section>

								<section className='fade-cloud2'>
									<img className='cloud2-1' width='423' height='226' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
									<img className='cloud2-2' width='434' height='231' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
								</section>

								<section className='fade-cloud4'>
									<img className='cloud4-1' width='442' height='236' src={imageRootPath + '/images/landing/cloud-r.webp'} alt='cloud' />
									<img className='cloud4-2' width='449' height='240' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />
								</section>

								<section className='fade-cloud6'>
									<img className='cloud6-1' width='618' height='330' src={imageRootPath + '/images/landing/cloud-r.webp'} alt='cloud' />
									<img className='cloud6-2' width='628' height='335' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />
								</section>

								<section className='fade-cloud3'>
									<img className='cloud3-1' width='450' height='240' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
									<img className='cloud3-2' width='450' height='240' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
								</section>
							</section>

							<section className='fade-backdrop'>
								<img className='op-black' src={imageRootPath + '/images/landing/op-black.svg'} alt='op black' />
							</section>
						</section>

						<section className='fade2'>
							<img width='2208' className='bg-king' src={imageRootPath + '/images/landing/bg-king.webp'} alt='bg king' />
							<img width={isEnding ? '272' : '258'} className='king-welcome-text' src={isEnding ? imageRootPath + '/images/landing/loopback-title.webp' : imageRootPath + '/images/landing/king-welcome-text.webp'} alt='king welcome text' />

							<img width='382' className='lsm99king-text' src={imageRootPath + '/images/landing/lsm99king-text.webp'} alt='lsm99king' />
							<img height='455' className='the-king' src={imageRootPath + '/images/landing/the-king.webp'} alt='the king' />

							<button className='btn-login w-btn' onClick={() => onLogin()}>เข้าสู่ระบบ</button>
							<button className='btn-register w-btn-outline' onClick={() => onRegister()}>สมัครสมาชิก</button>

							<img height='32' width='32' className='scroll-mouse' src={imageRootPath + '/images/landing/mouse-icon.svg'} alt='scroll mouse' />

							<p className='p2 scroll-text'>เลื่อนลงเพื่อดูรายละเอียด</p>
						</section>

						<section className='fade3 clearfix'>
							<img className='mask' width='364' height='auto' src={imageRootPath + '/images/landing/mask-lsm99king.webp'} alt='lsm99king' />

							<div className='navbar'>
								<button className='navbar-item w-btn-outline' onClick={() => onRegister()}>สมัครสมาชิก</button>
								<button className='navbar-item w-btn' onClick={() => onLogin()}>เข้าสู่ระบบ</button>

								<div className='navbar-logo-outer'>
									<img className='navbar-logo' width='60' height='60' src={imageRootPath + '/images/landing/king-logo.webp'} alt='king logo' />
								</div>
							</div>

							<h1 className='title-1'>
								เว็บชั้นนำ<span>ระดับประเทศ</span><br />
								ที่ใครใช้ก็<span>ติดใจ</span>กันทุกคน
							</h1>

							<p className='p1 subtitle-1'>
								ยินดีต้อนรับสู่เว็บพนันออนไลน์ของเรา <span className='text--primary'>LSM99KiNG</span><br />
								ที่มอบความสนุกและความตื่นเต้นให้กับคุณด้วยเกมพนันหลากหลาย
								เช่น <b>แทงบอล บาคาร่า สล็อต รูเล็ต และหวยออนไลน์</b>
								ทุกเกมถูกออกแบบมาเพื่อให้คุณได้รับประสบการณ์ที่ดีที่สุด
								พร้อมโปรโมชั่นและโบนัสมากมายที่พร้อมให้คุณได้รับประโยชน์
								ไม่ว่าจะ<b>เป็นโบนัสต้อนรับสำหรับสมาชิกใหม่ โปรโมชั่นฝากเงิน รายวัน
									และโบนัสคืนยอดเสีย</b> โดยที่คุณมั่นใจได้ว่า การเล่นพนันกับเราจะเป็นไปอย่างปลอดภัยและโปร่งใส
								ทีมงานบริการลูกค้าของเราพร้อมให้<b>บริการตลอด 24 ชั่วโมง</b> ทุกวัน ร่วมสนุกกับเราได้ทุกที่ทุกเวลา
							</p>

							<div className='smartphone'>
								<div className='phone-wrap'>
									<img className='phone-1' src={imageRootPath + '/images/landing/phone-1.webp'} alt='phone 1' />
									<img className='phone-2' src={imageRootPath + '/images/landing/phone-2.webp'} alt='phone 2' />
									<img className='phone-3' src={imageRootPath + '/images/landing/phone-3.webp'} alt='phone 3' />
									<img className='phone-4' src={imageRootPath + '/images/landing/phone-4.webp'} alt='phone 4' />
									<img className='phone-5' src={imageRootPath + '/images/landing/phone-5.webp'} alt='phone 5' />
								</div>
							</div>

							<div className='balloons'>
								<img className='balloon-1' src={imageRootPath + '/images/landing/balloon-1.webp'} alt='balloon 1' />
								<img className='balloon-2' src={imageRootPath + '/images/landing/balloon-2.webp'} alt='balloon 2' />
								<img className='balloon-3' src={imageRootPath + '/images/landing/balloon-3.webp'} alt='balloon 3' />
								<img className='balloon-4' src={imageRootPath + '/images/landing/balloon-4.webp'} alt='balloon 4' />
								<img className='balloon-5' src={imageRootPath + '/images/landing/balloon-5.webp'} alt='balloon 5' />
								<img className='balloon-6' src={imageRootPath + '/images/landing/balloon-6.webp'} alt='balloon 6' />
								<img className='balloon-7' src={imageRootPath + '/images/landing/balloon-7.webp'} alt='balloon 7' />
								<img className='balloon-8' src={imageRootPath + '/images/landing/balloon-8.webp'} alt='balloon 8' />
								<img className='balloon-9' src={imageRootPath + '/images/landing/balloon-9.webp'} alt='balloon 9' />
								<img className='balloon-10' src={imageRootPath + '/images/landing/balloon-10.webp'} alt='balloon 10' />
								<img className='balloon-11' src={imageRootPath + '/images/landing/balloon-11.webp'} alt='balloon 11' />
							</div>

							<div className='balloon-texts'>
								<p className='p2 balloon-text-1'>LSM99KiNG โปรโมชั่น</p>

								<div className='balloon-inner i-1'>
									<div className='balloon-title t-1'>โปรโมชั่น</div>
									<div className='balloon-title t-2'>คุ้มสุด ๆ</div>
								</div>

								<div className='balloon-inner i-2'>
									<div className='balloon-title t-3'>มีให้เลือก</div>
									<div className='balloon-title t-4'>เยอะ จุก ๆ</div>
								</div>

								<p className='p1 balloon-text-2'>
									<span>ครอบคลุม</span>ทุกความต้องการของนักเดิมพัน คุณชอบอะไร<br />
									เราจัดมาให้<span>ครบทุกโปร ทุกประเภท</span> ใส่ใจในทุกกิจกรรม จังหวะ<br />
									ของชีวิตคุณด้วย โปรวันเกิด โปรเลขท้ายบิลการโอน<br />
									แบะไม่ว่าคุณจะ <span>เล่นได้หรือเสีย เราก็มีโปรรองรับ</span> เช่นกัน
								</p>

								<div className='category-show'>
									<div className='w-category cate-1 active'>
										<div className='category-inner'>
											<span className='category-text'>กีฬา</span>
										</div>
										<img className='category-img' src={imageRootPath + '/images/landing/sport-active.webp'} alt='' draggable='false' />
									</div>

									<div className='w-category cate-2'>
										<div className='category-inner'>
											<span className='category-text'>คาสิโน</span>
										</div>
										<img className='category-img' src={imageRootPath + '/images/landing/casino-active.webp'} alt='' draggable='false' />
									</div>

									<div className='w-category cate-3'>
										<div className='category-inner'>
											<span className='category-text'>สล็อต</span>
										</div>
										<img className='category-img' src={imageRootPath + '/images/landing/slot-active.webp'} alt='' draggable='false' />
									</div>

									<div className='w-category cate-4'>
										<div className='category-inner'>
											<span className='category-text'>หวย</span>
										</div>
										<img className='category-img' src={imageRootPath + '/images/landing/lotto-active.webp'} alt='' draggable='false' />
									</div>

									<div className='w-category cate-5'>
										<div className='category-inner'>
											<span className='category-text'>อื่น ๆ</span>
										</div>
										<img className='category-img' src={imageRootPath + '/images/landing/other-active.webp'} alt='' draggable='false' />
									</div>
								</div>

								<div className='pre-show'>
									<div className='pre-sport'>
										<img className='sp-1' src={imageRootPath + '/images/landing/preshow/sp-1.webp'} alt='sport 1' />
										<img className='sp-2' src={imageRootPath + '/images/landing/preshow/sp-2.webp'} alt='sport 2' />
										<img className='sp-3' src={imageRootPath + '/images/landing/preshow/sp-3.webp'} alt='sport 3' />
										<img className='sp-4' src={imageRootPath + '/images/landing/preshow/sp-4.webp'} alt='sport 4' />
										<img className='sp-5' src={imageRootPath + '/images/landing/preshow/sp-5.webp'} alt='sport 5' />
										<img className='sp-6' src={imageRootPath + '/images/landing/preshow/sp-6.webp'} alt='sport 6' />
										<img className='sp-7' src={imageRootPath + '/images/landing/preshow/sp-7.webp'} alt='sport 7' />
										<img className='sp-8' src={imageRootPath + '/images/landing/preshow/sp-8.webp'} alt='sport 8' />
										<img className='sp-9' src={imageRootPath + '/images/landing/preshow/sp-9.webp'} alt='sport 9' />
									</div>

									<div className='pre-casino'>
										<img className='cs-1' src={imageRootPath + '/images/landing/preshow/cs-1.webp'} alt='casino 1' />
										<img className='cs-2' src={imageRootPath + '/images/landing/preshow/cs-2.webp'} alt='casino 2' />
										<img className='cs-3' src={imageRootPath + '/images/landing/preshow/cs-3.webp'} alt='casino 3' />
										<img className='cs-4' src={imageRootPath + '/images/landing/preshow/cs-4.webp'} alt='casino 4' />
										<img className='cs-5' src={imageRootPath + '/images/landing/preshow/cs-5.webp'} alt='casino 5' />
										<img className='cs-6' src={imageRootPath + '/images/landing/preshow/cs-6.webp'} alt='casino 6' />
										<img className='cs-7' src={imageRootPath + '/images/landing/preshow/cs-7.webp'} alt='casino 7' />
										<img className='cs-8' src={imageRootPath + '/images/landing/preshow/cs-8.webp'} alt='casino 8' />
										<img className='cs-9' src={imageRootPath + '/images/landing/preshow/cs-9.webp'} alt='casino 9' />
										<img className='cs-10' src={imageRootPath + '/images/landing/preshow/cs-10.webp'} alt='casino 10' />
										<img className='cs-11' src={imageRootPath + '/images/landing/preshow/cs-11.webp'} alt='casino 11' />
										<img className='cs-12' src={imageRootPath + '/images/landing/preshow/cs-12.webp'} alt='casino 12' />
										<img className='cs-13' src={imageRootPath + '/images/landing/preshow/cs-13.webp'} alt='casino 13' />
									</div>

									<div className='pre-slot'>
										<img className='sl-1' src={imageRootPath + '/images/landing/preshow/sl-1.webp'} alt='slot 1' />
										<img className='sl-2' src={imageRootPath + '/images/landing/preshow/sl-2.webp'} alt='slot 2' />
										<img className='sl-3' src={imageRootPath + '/images/landing/preshow/sl-3.webp'} alt='slot 3' />
										<img className='sl-4' src={imageRootPath + '/images/landing/preshow/sl-4.webp'} alt='slot 4' />
										<img className='sl-6' src={imageRootPath + '/images/landing/preshow/sl-6.webp'} alt='slot 6' />
										<img className='sl-7' src={imageRootPath + '/images/landing/preshow/sl-7.webp'} alt='slot 7' />
										<img className='sl-8' src={imageRootPath + '/images/landing/preshow/sl-8.webp'} alt='slot 8' />
										<img className='sl-9' src={imageRootPath + '/images/landing/preshow/sl-9.webp'} alt='slot 9' />
										<img className='sl-10' src={imageRootPath + '/images/landing/preshow/sl-10.webp'} alt='slot 10' />
										<img className='sl-11' src={imageRootPath + '/images/landing/preshow/sl-11.webp'} alt='slot 11' />
										<img className='sl-12' src={imageRootPath + '/images/landing/preshow/sl-12.webp'} alt='slot 12' />
										<img className='sl-13' src={imageRootPath + '/images/landing/preshow/sl-13.webp'} alt='slot 13' />
										<img className='sl-14' src={imageRootPath + '/images/landing/preshow/sl-14.webp'} alt='slot 14' />
									</div>

									<div className='pre-lotto'>
										<img className='lt-1' src={imageRootPath + '/images/landing/preshow/lt-1.webp'} alt='lotto 1' />
										<img className='lt-2' src={imageRootPath + '/images/landing/preshow/lt-2.webp'} alt='lotto 2' />
										<img className='lt-3' src={imageRootPath + '/images/landing/preshow/lt-3.webp'} alt='lotto 3' />
										<img className='lt-4' src={imageRootPath + '/images/landing/preshow/lt-4.webp'} alt='lotto 4' />
										<img className='lt-6' src={imageRootPath + '/images/landing/preshow/lt-6.webp'} alt='lotto 6' />
										<img className='lt-7' src={imageRootPath + '/images/landing/preshow/lt-7.webp'} alt='lotto 7' />
										<img className='lt-8' src={imageRootPath + '/images/landing/preshow/lt-8.webp'} alt='lotto 8' />
										<img className='lt-9' src={imageRootPath + '/images/landing/preshow/lt-9.webp'} alt='lotto 9' />
										<img className='lt-10' src={imageRootPath + '/images/landing/preshow/lt-10.webp'} alt='lotto 10' />
										<img className='lt-11' src={imageRootPath + '/images/landing/preshow/lt-11.webp'} alt='lotto 11' />
										<img className='lt-12' src={imageRootPath + '/images/landing/preshow/lt-12.webp'} alt='lotto 12' />
									</div>

									<div className='pre-other'>
										<img className='ot-1' src={imageRootPath + '/images/landing/preshow/ot-1.webp'} alt='other 1' />
										<img className='ot-2' src={imageRootPath + '/images/landing/preshow/ot-2.webp'} alt='other 2' />
										<img className='ot-3' src={imageRootPath + '/images/landing/preshow/ot-3.webp'} alt='other 3' />
										<img className='ot-4' src={imageRootPath + '/images/landing/preshow/ot-4.webp'} alt='other 4' />
										<img className='ot-5' src={imageRootPath + '/images/landing/preshow/ot-5.webp'} alt='other 5' />
										<img className='ot-6' src={imageRootPath + '/images/landing/preshow/ot-6.webp'} alt='other 6' />
										<img className='ot-7' src={imageRootPath + '/images/landing/preshow/ot-7.webp'} alt='other 7' />
										<img className='ot-8' src={imageRootPath + '/images/landing/preshow/ot-8.webp'} alt='other 8' />
										<img className='ot-9' src={imageRootPath + '/images/landing/preshow/ot-9.webp'} alt='other 9' />
										<img className='ot-10' src={imageRootPath + '/images/landing/preshow/ot-10.webp'} alt='other 10' />
									</div>
								</div>
							</div>

							<div className='provider-section'>
								<p className='p2 provider-text-1'>LSM99KiNG แนะนำเพื่อนสร้างรายได้</p>

								<div className='provider-inner i-1'>
									<div className='provider-title t-1'>คุณภาพ</div>
									<div className='provider-title t-2'>ล้นเหลือ</div>
								</div>

								<div className='provider-inner i-2'>
									<div className='provider-title t-3'>กับค่ายเกม</div>
									<div className='provider-title t-4'>ชั้นนำ</div>
								</div>

								<div className='provider-list'>
									<img className='pv pv-1' width='116' src={imageRootPath + '/images/landing/providers/pv-1.webp'} alt='provider 1' />
									<img className='pv pv-2' width='52' src={imageRootPath + '/images/landing/providers/pv-2.webp'} alt='provider 2' />
									<img className='pv pv-3' width='125.5' src={imageRootPath + '/images/landing/providers/pv-3.webp'} alt='provider 3' />
									<img className='pv pv-4' width='74' src={imageRootPath + '/images/landing/providers/pv-4.webp'} alt='provider 4' />

									<img className='pv pv-5' width='68' src={imageRootPath + '/images/landing/providers/pv-5.webp'} alt='provider 5' />
									<img className='pv pv-6' width='117.5' src={imageRootPath + '/images/landing/providers/pv-6.webp'} alt='provider 6' />
									<img className='pv pv-7' width='67' src={imageRootPath + '/images/landing/providers/pv-7.webp'} alt='provider 7' />
									<img className='pv pv-8' width='72' src={imageRootPath + '/images/landing/providers/pv-8.webp'} alt='provider 8' />

									<img className='pv pv-9' width='73' src={imageRootPath + '/images/landing/providers/pv-9.webp'} alt='provider 9' />
									<img className='pv pv-10' width='59.5' src={imageRootPath + '/images/landing/providers/pv-10.webp'} alt='provider 10' />
									<img className='pv pv-11' width='142' src={imageRootPath + '/images/landing/providers/pv-11.webp'} alt='provider 11' />
									<img className='pv pv-12' width='68' src={imageRootPath + '/images/landing/providers/pv-12.webp'} alt='provider 12' />
									<img className='pv pv-13' width='54' src={imageRootPath + '/images/landing/providers/pv-13.webp'} alt='provider 13' />

									<img className='pv pv-14' width='57.5' src={imageRootPath + '/images/landing/providers/pv-14.webp'} alt='provider 14' />
									<img className='pv pv-15' width='63.5' src={imageRootPath + '/images/landing/providers/pv-15.webp'} alt='provider 15' />
									<img className='pv pv-16' width='51.5' src={imageRootPath + '/images/landing/providers/pv-16.webp'} alt='provider 16' />
									<img className='pv pv-17' width='46' src={imageRootPath + '/images/landing/providers/pv-17.webp'} alt='provider 17' />
									<img className='pv pv-18' width='115' src={imageRootPath + '/images/landing/providers/pv-18.webp'} alt='provider 18' />
									<img className='pv pv-19' width='58' src={imageRootPath + '/images/landing/providers/pv-19.webp'} alt='provider 19' />
									<img className='pv pv-20' width='53' src={imageRootPath + '/images/landing/providers/pv-20.webp'} alt='provider 20' />

									<img className='pv pv-21' width='93' src={imageRootPath + '/images/landing/providers/pv-21.webp'} alt='provider 21' />
									<img className='pv pv-22' width='95.5' src={imageRootPath + '/images/landing/providers/pv-22.webp'} alt='provider 22' />
									<img className='pv pv-23' width='195' src={imageRootPath + '/images/landing/providers/pv-23.webp'} alt='provider 23' />
									<img className='pv pv-i' width='52' src={imageRootPath + '/images/landing/providers/pv-2.webp'} alt='provider 2' />

									<img className='pv pv-24' width='80' src={imageRootPath + '/images/landing/providers/pv-24.webp'} alt='provider 24' />
									<img className='pv pv-25' width='67' src={imageRootPath + '/images/landing/providers/pv-25.webp'} alt='provider 25' />
									<img className='pv pv-26' width='113' src={imageRootPath + '/images/landing/providers/pv-26.webp'} alt='provider 26' />
									<img className='pv pv-27' width='103' src={imageRootPath + '/images/landing/providers/pv-27.webp'} alt='provider 27' />
									<img className='pv pv-28' width='70' src={imageRootPath + '/images/landing/providers/pv-28.webp'} alt='provider 28' />

									<img className='pv pv-29' width='131' src={imageRootPath + '/images/landing/providers/pv-29.webp'} alt='provider 29' />
									<img className='pv pv-30' width='73' src={imageRootPath + '/images/landing/providers/pv-30.webp'} alt='provider 30' />
									<img className='pv pv-ii' width='59.5' src={imageRootPath + '/images/landing/providers/pv-10.webp'} alt='provider 10' />

									<img className='pv pv-31' width='112' src={imageRootPath + '/images/landing/providers/pv-31.webp'} alt='provider 31' />
									<img className='pv pv-iii' width='102' src={imageRootPath + '/images/landing/providers/pv-40.webp'} alt='provider 40' />
									<img className='pv pv-j' width='57.5' src={imageRootPath + '/images/landing/providers/pv-14.webp'} alt='provider 14' />
									<img className='pv pv-jj' width='63.5' src={imageRootPath + '/images/landing/providers/pv-15.webp'} alt='provider 15' />

									<img className='pv pv-32' width='103' src={imageRootPath + '/images/landing/providers/pv-32.webp'} alt='provider 32' />
									<img className='pv pv-33' width='51' src={imageRootPath + '/images/landing/providers/pv-33.webp'} alt='provider 33' />
									<img className='pv pv-34' width='96' src={imageRootPath + '/images/landing/providers/pv-34.webp'} alt='provider 34' />
									<img className='pv pv-jjj' width='59' src={imageRootPath + '/images/landing/providers/pv-21.webp'} alt='provider 21' />
									<img className='pv pv-k' width='51' src={imageRootPath + '/images/landing/providers/pv-7.webp'} alt='provider 7' />

									<img className='pv pv-35' width='58' src={imageRootPath + '/images/landing/providers/pv-35.webp'} alt='provider 35' />
									<img className='pv pv-36' width='171' src={imageRootPath + '/images/landing/providers/pv-36.webp'} alt='provider 36' />
									<img className='pv pv-37' width='79' src={imageRootPath + '/images/landing/providers/pv-37.webp'} alt='provider 37' />
									<img className='pv pv-kk' width='72' src={imageRootPath + '/images/landing/providers/pv-26.webp'} alt='provider 26' />

									<img className='pv pv-38' width='160' src={imageRootPath + '/images/landing/providers/pv-38.webp'} alt='provider 38' />
									<img className='pv pv-39' width='73' src={imageRootPath + '/images/landing/providers/pv-39.webp'} alt='provider 39' />
									<img className='pv pv-kkk' width='111' src={imageRootPath + '/images/landing/providers/pv-1.webp'} alt='provider 1' />
								</div>
							</div>

							<div className='friend-section'>
								<div className='friend-ipad'>
									<img className='ipad-mock' src={imageRootPath + '/images/landing/ipad-mock.webp'} alt='ipad mock' />

									<div className='friend-wrapper'>
										<img className='friend-bg' src={imageRootPath + '/images/landing/friend-bg.webp'} alt='friend bg' />
									</div>
								</div>

								<p className='p2 friend-text-1'>LSM99KiNG แนะนำเพื่อนสร้างรายได้</p>

								<div className='t1 friend-title-1'>ให้เพื่อนคุณเยอะ</div>
								<div className='t1 friend-title-2'>เหมือนในชีวิตจริง</div>

								<p className='p1 friend-text-2'>ไม่ใช่เข้ามาแค่เล่นพนันกันอย่างเดียว</p>
								<p className='p1 friend-text-3'>ถ้าคุณเป็นคน<b>เพื่อนเยอะ</b>ล่ะก็ นี่และคือ<b>โอกาส !</b></p>
								<p className='p1 friend-text-4'>เพื่อนคุณ<b>เล่นปุ๊บ</b> ได้<b>รับเงินปั๊บ</b></p>

								<div className='t1 friend-title-3'>ยิ่งชวนเยอะ<span>ยิ่งได้เยอะ</span></div>
								<div className='t1 friend-title-4'>เพื่อนเล่นเยอะ<span>ก็ได้เยอะ</span></div>
								<p className='p1 friend-text-5'>ไม่ว่าเพื่อนคุณจะได้หรือเสีย เราก็ยังให้<span>คุณได้เหมือนเดิม</span></p>
								<p className='p1 friend-text-6'>ทั้ง<span>คืนเยอะเล่น และยอดเสีย</span>ของเพื่อนที่คุณแนะนำมา</p>
								<p className='p1 friend-text-7'>ยิ่งไปกว่านั้น ถ้าเพื่อนคุณไปชวนอีกต่อ <span>คุณก็ยังได้รับอีก</span></p>

								<span className='t1 friend-title-5'>เว็บดีขนาดนี้ มัวรออะไร</span>
							</div>
						</section>
					</React.Fragment>
				) : null}
			</div>




			{isDesktop ? (
				<div className='landing-desktop'>
					{/* @ Fade Logo */}
					<section className='frame-logo'>
						<div className='logo-outer'>
							<div className='logo-inner'>
								<img className='king-logo' width='247' height='247' src={imageRootPath + '/images/landing/king-logo.webp'} alt='king logo' />
							</div>
						</div>
					</section>

					{!ready ? (
						<section className='frame-1'>

							<img className='frame-bg' src={imageRootPath + '/images/landing/desktop/bg.webp'} alt='bg' />
							<img className='frame-castle' src={imageRootPath + '/images/landing/desktop/castle.webp'} alt='castle' />
							<img className='pole-start' src={imageRootPath + '/images/landing/desktop/pole.webp'} alt='pole' />
							<img className='pole-end' src={imageRootPath + '/images/landing/desktop/pole.webp'} alt='pole' />

							<img className='welcome-text' src={imageRootPath + '/images/landing/desktop/welcome-text.webp'} alt='welcome text' />

							<section className='frame-clouds'>
								<img className='cloud2-1' width='1213' src={imageRootPath + '/images/landing/cloud-r.webp'} alt='cloud' />
								<img className='cloud2-2' width='1232' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />

								<img className='cloud3-1' width='1232' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />
								<img className='cloud3-2' width='599' src={imageRootPath + '/images/landing/cloud.webp'} alt='cloud' />

								<img className='cloud4-1' width='1162' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
								<img className='cloud4-2' width='1191' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />

								<img className='cloud5-1' width='463' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />
								<img className='cloud5-2' width='463' src={imageRootPath + '/images/landing/cloud-f.webp'} alt='cloud' />

								<img className='cloud6-1' width='1213' src={imageRootPath + '/images/landing/cloud-r.webp'} alt='cloud' />
								<img className='cloud6-2' width='1232' src={imageRootPath + '/images/landing/cloud-l.webp'} alt='cloud' />
							</section>

							<div className='frame-op' />
						</section>
					) : null}

					<section className='frame-2'>
						<img className='bg-king' src={imageRootPath + '/images/landing/desktop/king-bg.webp'} alt='bg king' />

						<img width='1008' className='king-title-top' src={imageRootPath + '/images/landing/desktop/king-title-top.webp'} alt='king title top' />
						<img width='1008' className='king-title-bottom' src={imageRootPath + '/images/landing/desktop/king-title-bottom.webp'} alt='king title bottom' />

						<img className='login-text' src={imageRootPath + '/images/landing/desktop/login-text.webp'} alt='login text' />
						<img className='register-text' src={imageRootPath + '/images/landing/desktop/register-text.webp'} alt='register text' />

						<img height='135' className='king-welcome' src={isEnding ? imageRootPath + '/images/landing/desktop/loopback-title.webp' : imageRootPath + '/images/landing/desktop/welcome-to.webp'} alt='king welcome' />
						<img width='85' className='the-king' src={imageRootPath + '/images/landing/desktop/the-king.webp'} alt='the king' />

						<button className='btn-login w-btn' onClick={() => onLogin()}>เข้าสู่ระบบ</button>
						<button className='btn-register w-btn-outline' onClick={() => onRegister()}>สมัครสมาชิก</button>

						<img className='scroll-mouse' src={imageRootPath + '/images/landing/desktop/scroll-mouse.svg'} alt='scroll mouse' />
						<p className='p1 scroll-text'>เลื่อนลงเพื่อดูรายละเอียด</p>
					</section>

					<section className='frame-3'>
						<img width='475' className='mask' src={imageRootPath + '/images/landing/desktop/mask.webp'} alt='mask' />

						<div className='w-header'>LSM99KiNG</div>
						<div className='w-title t1 text-highlight'>
							เว็บชั้นนำ<span>ระดับประเทศ</span>ที่ใครใช้ก็<span>ติดใจ</span>กันทุกคน
						</div>
						<p className='w-subtitle p1 text-highlight'>
							ยินดีต้อนรับสู่เว็บพนันออนไลน์ของเรา <span className='text--primary'>LSM99KiNG</span> ที่มอบความสนุกและความตื่นเต้นให้กับ
							คุณด้วยเกมพนันหลากหลาย เช่น <span>แทงบอล บาคาร่า สล็อต รูเล็ต และหวยออนไลน์</span> ทุกเกมถูกออกแบบมาเพื่อให้คุณได้รับประสบการณ์ที่ดีที่สุด
							พร้อมโปรโมชั่นและโบนัสมากมายที่พร้อมให้คุณได้รับประโยชน์ ไม่ว่าจะเป็น<span>โบนัสต้อนรับสำหรับสมาชิกใหม่{' '}
								โปรโมชั่นฝากเงิน รายวัน และโบนัสคืนยอดเสีย</span> โดยที่คุณมั่นใจได้ว่า{' '}
							การเล่นพนันกับเราจะเป็นไปอย่างปลอดภัยและโปร่งใส ทีมงานบริการลูกค้าของเราพร้อมให้<span>บริการตลอด 24 ชั่วโมง</span> ทุกวัน ร่วมสนุกกับเราได้ทุกที่ทุกเวลา
						</p>

						<div className='smartphone'>
							<div className='phone-wrapper'>
								<img className='phone-1' src={imageRootPath + '/images/landing/phone-1.webp'} alt='phone 1' />
								<img className='phone-2' src={imageRootPath + '/images/landing/phone-2.webp'} alt='phone 2' />
								<img className='phone-3' src={imageRootPath + '/images/landing/phone-3.webp'} alt='phone 3' />
								<img className='phone-4' src={imageRootPath + '/images/landing/phone-4.webp'} alt='phone 4' />
								<img className='phone-5' src={imageRootPath + '/images/landing/phone-5.webp'} alt='phone 5' />
							</div>
						</div>

						<div className='balloon-wrapper'>
							<img className='balloon-1' src={imageRootPath + '/images/landing/desktop/balloon-1.webp'} alt='balloon 1' />
							<img className='balloon-2' src={imageRootPath + '/images/landing/desktop/balloon-2.webp'} alt='balloon 2' />
							<img className='balloon-3' src={imageRootPath + '/images/landing/desktop/balloon-3.webp'} alt='balloon 3' />
							<img className='balloon-4' src={imageRootPath + '/images/landing/desktop/balloon-4.webp'} alt='balloon 4' />
							<img className='balloon-5' src={imageRootPath + '/images/landing/desktop/balloon-5.webp'} alt='balloon 5' />
							<img className='balloon-6' src={imageRootPath + '/images/landing/desktop/balloon-6.webp'} alt='balloon 6' />
							<img className='balloon-7' src={imageRootPath + '/images/landing/desktop/balloon-7.webp'} alt='balloon 7' />
							<img className='balloon-8' src={imageRootPath + '/images/landing/desktop/balloon-8.webp'} alt='balloon 8' />
							<img className='balloon-9' src={imageRootPath + '/images/landing/desktop/balloon-9.webp'} alt='balloon 9' />
							<img className='balloon-10' src={imageRootPath + '/images/landing/desktop/balloon-10.webp'} alt='balloon 10' />
							<img className='balloon-11' src={imageRootPath + '/images/landing/desktop/balloon-11.webp'} alt='balloon 11' />
						</div>

						<div className='balloon-texts'>
							<div className='balloon-header'>LSM99KiNG โปรโมชั่น</div>
							<div className='balloon-title bt-1'>โปรโมชั่น</div>
							<div className='balloon-title bt-2 text-white-100'>คุ้มสุด ๆ</div>
							<div className='balloon-title bt-3'>มีให้เลือก</div>
							<div className='balloon-title bt-4 text-white-100'>เยอะ จุก ๆ</div>
							<p className='p1 bt-5 text-highlight'>
								<span>ครอบคลุม</span>ทุกความต้องการของนักเดิมพัน คุณชอบอะไร<br />
								เราจัดมา<span>ให้ครบทุกโปร ทุกประเภท</span> ใส่ใจในทุกกิจกรรม จังหวะ<br />
								ของชีวิตคุณด้วย โปรวันเกิด โปรเลขท้ายบิลการโอน<br />
								แบะไม่ว่าคุณจะ <span>เล่นได้หรือเสีย เราก็มีโปรรองรับ</span> เช่นกัน
							</p>
						</div>

						<div className='category-show'>
							<div className='w-category cate-1 active'>
								<div className='category-inner'>
									<span className='category-text'>กีฬา</span>
								</div>
								<img className='category-img' src={imageRootPath + '/images/landing/sport-active.webp'} alt='' draggable='false' />
							</div>

							<div className='w-category cate-2'>
								<div className='category-inner'>
									<span className='category-text'>คาสิโน</span>
								</div>
								<img className='category-img' src={imageRootPath + '/images/landing/casino-active.webp'} alt='' draggable='false' />
							</div>

							<div className='w-category cate-3'>
								<div className='category-inner'>
									<span className='category-text'>สล็อต</span>
								</div>
								<img className='category-img' src={imageRootPath + '/images/landing/slot-active.webp'} alt='' draggable='false' />
							</div>

							<div className='w-category cate-4'>
								<div className='category-inner'>
									<span className='category-text'>หวย</span>
								</div>
								<img className='category-img' src={imageRootPath + '/images/landing/lotto-active.webp'} alt='' draggable='false' />
							</div>

							<div className='w-category cate-5'>
								<div className='category-inner'>
									<span className='category-text'>อื่น ๆ</span>
								</div>
								<img className='category-img' src={imageRootPath + '/images/landing/other-active.webp'} alt='' draggable='false' />
							</div>
						</div>

						<div className='pre-show'>
							<div className='pre-sport'>
								<img className='sp-1' src={imageRootPath + '/images/landing/desktop/preshow/sp-1.webp'} alt='sport 1' />
								<img className='sp-2' src={imageRootPath + '/images/landing/desktop/preshow/sp-2.webp'} alt='sport 2' />
								<img className='sp-3' src={imageRootPath + '/images/landing/desktop/preshow/sp-3.webp'} alt='sport 3' />
								<img className='sp-4' src={imageRootPath + '/images/landing/desktop/preshow/sp-4.webp'} alt='sport 4' />
								<img className='sp-5' src={imageRootPath + '/images/landing/desktop/preshow/sp-5.webp'} alt='sport 5' />
								<img className='sp-6' src={imageRootPath + '/images/landing/desktop/preshow/sp-6.webp'} alt='sport 6' />
							</div>

							<div className='pre-casino'>
								<img className='cs-1' src={imageRootPath + '/images/landing/desktop/preshow/cs-1.webp'} alt='casino 1' />
								<img className='cs-2' src={imageRootPath + '/images/landing/desktop/preshow/cs-2.webp'} alt='casino 2' />
								<img className='cs-3' src={imageRootPath + '/images/landing/desktop/preshow/cs-3.webp'} alt='casino 3' />
								<img className='cs-4' src={imageRootPath + '/images/landing/desktop/preshow/cs-4.webp'} alt='casino 4' />
								<img className='cs-5' src={imageRootPath + '/images/landing/desktop/preshow/cs-5.webp'} alt='casino 5' />
								<img className='cs-6' src={imageRootPath + '/images/landing/desktop/preshow/cs-6.webp'} alt='casino 6' />
								<img className='cs-7' src={imageRootPath + '/images/landing/desktop/preshow/cs-7.webp'} alt='casino 7' />
								<img className='cs-8' src={imageRootPath + '/images/landing/desktop/preshow/cs-8.webp'} alt='casino 8' />
								<img className='cs-9' src={imageRootPath + '/images/landing/desktop/preshow/cs-9.webp'} alt='casino 9' />
							</div>

							<div className='pre-slot'>
								<img className='sl-1' src={imageRootPath + '/images/landing/desktop/preshow/sl-1.webp'} alt='slot 1' />
								<img className='sl-2' src={imageRootPath + '/images/landing/desktop/preshow/sl-2.webp'} alt='slot 2' />
								<img className='sl-3' src={imageRootPath + '/images/landing/desktop/preshow/sl-3.webp'} alt='slot 3' />
								<img className='sl-4' src={imageRootPath + '/images/landing/desktop/preshow/sl-4.webp'} alt='slot 4' />
								<img className='sl-5' src={imageRootPath + '/images/landing/desktop/preshow/sl-5.webp'} alt='slot 5' />
								<img className='sl-6' src={imageRootPath + '/images/landing/desktop/preshow/sl-6.webp'} alt='slot 6' />
								<img className='sl-7' src={imageRootPath + '/images/landing/desktop/preshow/sl-7.webp'} alt='slot 7' />
								<img className='sl-8' src={imageRootPath + '/images/landing/desktop/preshow/sl-8.webp'} alt='slot 8' />
							</div>

							<div className='pre-lotto'>
								<img className='lt-1' src={imageRootPath + '/images/landing/desktop/preshow/lt-1.webp'} alt='lotto 1' />
								<img className='lt-2' src={imageRootPath + '/images/landing/desktop/preshow/lt-2.webp'} alt='lotto 2' />
								<img className='lt-3' src={imageRootPath + '/images/landing/desktop/preshow/lt-3.webp'} alt='lotto 3' />
								<img className='lt-4' src={imageRootPath + '/images/landing/desktop/preshow/lt-4.webp'} alt='lotto 4' />
								<img className='lt-5' src={imageRootPath + '/images/landing/desktop/preshow/lt-5.webp'} alt='lotto 5' />
								<img className='lt-6' src={imageRootPath + '/images/landing/desktop/preshow/lt-6.webp'} alt='lotto 6' />
								<img className='lt-7' src={imageRootPath + '/images/landing/desktop/preshow/lt-7.webp'} alt='lotto 7' />
								<img className='lt-8' src={imageRootPath + '/images/landing/desktop/preshow/lt-8.webp'} alt='lotto 8' />
							</div>

							<div className='pre-other'>
								<img className='ot-1' src={imageRootPath + '/images/landing/desktop/preshow/ot-1.webp'} alt='other 1' />
								<img className='ot-2' src={imageRootPath + '/images/landing/desktop/preshow/ot-2.webp'} alt='other 2' />
								<img className='ot-3' src={imageRootPath + '/images/landing/desktop/preshow/ot-3.webp'} alt='other 3' />
								<img className='ot-4' src={imageRootPath + '/images/landing/desktop/preshow/ot-4.webp'} alt='other 4' />
								<img className='ot-5' src={imageRootPath + '/images/landing/desktop/preshow/ot-5.webp'} alt='other 5' />
								<img className='ot-6' src={imageRootPath + '/images/landing/desktop/preshow/ot-6.webp'} alt='other 6' />
								<img className='ot-7' src={imageRootPath + '/images/landing/desktop/preshow/ot-7.webp'} alt='other 7' />
							</div>
						</div>

						<div className='provider-wrapper'>
							<div className='provider-header'>LSM99KiNG แนะนำเพื่อนสร้างรายได้</div>

							<div className='provider-title pvt-1'>คุณภาพ</div>
							<div className='provider-title pvt-2'>ล้นเหลือ</div>

							<div className='provider-title pvt-3'>กับค่ายเกม</div>
							<div className='provider-title pvt-4'>ชั้นนำ</div>
							<div className='provider-title pvt-5'>ระดับโลก</div>

							<div className='provider-list'>
								<img className='pv pv-1' width='112' src={imageRootPath + '/images/landing/providers/pv-9.webp'} alt='provider 9' />
								<img className='pv pv-2' width='91' src={imageRootPath + '/images/landing/providers/pv-10.webp'} alt='provider 10' />
								<img className='pv pv-3' width='78.5' src={imageRootPath + '/images/landing/providers/pv-16.webp'} alt='provider 16' />
								<img className='pv pv-4' width='70.5' src={imageRootPath + '/images/landing/providers/pv-17.webp'} alt='provider 17' />
								<img className='pv pv-5' width='176' src={imageRootPath + '/images/landing/providers/pv-18.webp'} alt='provider 18' />

								<img className='pv pv-6' width='88.5' src={imageRootPath + '/images/landing/providers/pv-14.webp'} alt='provider 14' />
								<img className='pv pv-7' width='98' src={imageRootPath + '/images/landing/providers/pv-15.webp'} alt='provider 15' />
								<img className='pv pv-8' width='89' src={imageRootPath + '/images/landing/providers/pv-19.webp'} alt='provider 19' />
								<img className='pv pv-9' width='81' src={imageRootPath + '/images/landing/providers/pv-20.webp'} alt='provider 20' />
								<img className='pv pv-10' width='79.5' src={imageRootPath + '/images/landing/providers/pv-2.webp'} alt='provider 2' />

								<img className='pv pv-11' width='142.5' src={imageRootPath + '/images/landing/providers/pv-21.webp'} alt='provider 21' />
								<img className='pv pv-12' width='147' src={imageRootPath + '/images/landing/providers/pv-22.webp'} alt='provider 22' />
								<img className='pv pv-13' width='298.5' src={imageRootPath + '/images/landing/providers/pv-23.webp'} alt='provider 23' />

								<img className='pv pv-14' width='122.5' src={imageRootPath + '/images/landing/providers/pv-24.webp'} alt='provider 24' />
								<img className='pv pv-15' width='102.5' src={imageRootPath + '/images/landing/providers/pv-25.webp'} alt='provider 25' />
								<img className='pv pv-16' width='173' src={imageRootPath + '/images/landing/providers/pv-26.webp'} alt='provider 26' />
								<img className='pv pv-17' width='157.8' src={imageRootPath + '/images/landing/providers/pv-27.webp'} alt='provider 27' />
								<img className='pv pv-18' width='107.5' src={imageRootPath + '/images/landing/providers/pv-28.webp'} alt='provider 28' />

								<img className='pv pv-19' width='200.5' src={imageRootPath + '/images/landing/providers/pv-29.webp'} alt='provider 29' />
								<img className='pv pv-20' width='112' src={imageRootPath + '/images/landing/providers/pv-30.webp'} alt='provider 30' />
								<img className='pv pv-21' width='91' src={imageRootPath + '/images/landing/providers/pv-10.webp'} alt='provider 10' />

								<img className='pv pv-22' width='171.5' src={imageRootPath + '/images/landing/providers/pv-31.webp'} alt='provider 31' />
								<img className='pv pv-23' width='156' src={imageRootPath + '/images/landing/providers/pv-40.webp'} alt='provider 40' />
								<img className='pv pv-24' width='88.5' src={imageRootPath + '/images/landing/providers/pv-14.webp'} alt='provider 14' />
								<img className='pv pv-25' width='97.5' src={imageRootPath + '/images/landing/providers/pv-15.webp'} alt='provider 15' />

								<img className='pv pv-26' width='157.8' src={imageRootPath + '/images/landing/providers/pv-32.webp'} alt='provider 32' />
								<img className='pv pv-27' width='78' src={imageRootPath + '/images/landing/providers/pv-33.webp'} alt='provider 33' />
								<img className='pv pv-28' width='147' src={imageRootPath + '/images/landing/providers/pv-34.webp'} alt='provider 34' />
								<img className='pv pv-29' width='90.5' src={imageRootPath + '/images/landing/providers/pv-21.webp'} alt='provider 21' />
								<img className='pv pv-30' width='78.5' src={imageRootPath + '/images/landing/providers/pv-7.webp'} alt='provider 7' />

								<img className='pv pv-31' width='89' src={imageRootPath + '/images/landing/providers/pv-7.webp'} alt='provider 7' />
								<img className='pv pv-32' width='262' src={imageRootPath + '/images/landing/providers/pv-36.webp'} alt='provider 36' />
								<img className='pv pv-33' width='121' src={imageRootPath + '/images/landing/providers/pv-37.webp'} alt='provider 37' />
								<img className='pv pv-34' width='110' src={imageRootPath + '/images/landing/providers/pv-26.webp'} alt='provider 26' />

								<img className='pv pv-35' width='245' src={imageRootPath + '/images/landing/providers/pv-38.webp'} alt='provider 38' />
								<img className='pv pv-36' width='112' src={imageRootPath + '/images/landing/providers/pv-39.webp'} alt='provider 39' />
								<img className='pv pv-37' width='170' src={imageRootPath + '/images/landing/providers/pv-1.webp'} alt='provider 1' />
							</div>
						</div>

						<div className='friend-wrapper'>
							<div className='friend-ipad'>
								<img className='ipad-mock' src={imageRootPath + '/images/landing/ipad-mock.webp'} alt='ipad mock' />

								<div className='friend-wrap'>
									<img className='friend-bg' src={imageRootPath + '/images/landing/friend-bg.webp'} alt='friend bg' />
								</div>
							</div>

							<div className='friend-header'>LSM99KiNG แนะนำเพื่อนสร้างรายได้</div>

							<div className='ft-1 friend-title'>ให้เพื่อนคุณเยอะ</div>
							<div className='ft-2 friend-title'>เหมือนในชีวิตจริง</div>

							<div className='ft-3 friend-text'>ไม่ใช่เข้ามาแค่เล่นพนันกันอย่างเดียว</div>
							<div className='ft-4 friend-text'>ถ้าคุณเป็นคน<b>เพื่อนเยอะ</b>ล่ะก็ นี่และคือ<b>โอกาส !</b></div>
							<div className='ft-5 friend-text'>เพื่อนคุณ<b>เล่นปุ๊บ</b> ได้<b>รับเงินปั๊บ</b></div>

							<div className='ft-6 friend-title-2'>ยิ่งชวนเยอะ<span>ยิ่งได้เยอะ</span></div>
							<div className='ft-7 friend-title-2'>เพื่อนเล่นเยอะ<span>ก็ได้เยอะ</span></div>
							<div className='ft-8 friend-text-2'>ไม่ว่าเพื่อนคุณจะได้หรือเสีย เราก็ยังให้<span>คุณได้เหมือนเดิม</span></div>
							<div className='ft-9 friend-text-2'>ทั้ง<span>คืนเยอะเล่น และยอดเสีย</span>ของเพื่อนที่คุณแนะนำมา</div>
							<div className='ft-10 friend-text-2'>ยิ่งไปกว่านั้น ถ้าเพื่อนคุณไปชวนอีกต่อ <span>คุณก็ยังได้รับอีก</span></div>

							<span className='ft-11 friend-title-2'>เว็บดีขนาดนี้ มัวรออะไร</span>
						</div>

						<div className='landing-navbar'>
							<div className='navbar-start' />

							<div className='navbar-logo'>
								<img className='logo' src={imageRootPath + '/images/landing/king-logo.webp'} alt='king logo' />
							</div>

							<div className='navbar-end'>
								<button className='w-btn-outline' 
								// onClick={() => {
									// setDesktopShowForm(null);
									// setFreezeObserver(null);
									// setDesktopToForm('register-' + UtilsRandom.nextCustom(5, UtilsRandom.numbers));
									// onRegister()
								// }}
								onClick={() => onRegister()}
								>สมัครสมาชิก</button>
								<button className='w-btn' 
								// onClick={() => {
									// setDesktopShowForm(null);
									// setFreezeObserver(null);
									// setDesktopToForm('login-' + UtilsRandom.nextCustom(5, UtilsRandom.numbers));
									// onLogin()
								// }}
								onClick={() => onLogin()}
								>เข้าสู่ระบบ</button>
							</div>
						</div>

					</section>
				</div>
			) : null}

		</div>
    </>
  );
}
