import { useEffect, useState } from 'react';

const screenWidth = Number(1024);

export default function UseDeviceScreens(def) {
    const [deviceScreen, setDeviceScreen] = useState(null);
	const [isReady, seIsReady] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setDeviceScreen(window.innerWidth || null);
		};

		seIsReady(true);
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

    let isMobile = Boolean(deviceScreen && deviceScreen <= screenWidth);
	let isDesktop = Boolean(deviceScreen && deviceScreen > screenWidth);

	if (!isReady && def) {
		if (def === 'mobile') isMobile = true;
		else isDesktop = true;
	}

	if (isReady && !isMobile) isDesktop = true;

	return { isMobile ,isDesktop };
}
